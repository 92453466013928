export interface ITheme {
  colors: {
    white: string;
    blueCyanDarkest: string;
    cyanDark: string;
    cyanLightest: string;
    grayLight: string;
    grayDark: string;
    redDanger: string;
    orange: string;
  },
  fonts: Array<string>;
}

export const theme: ITheme = {
  colors: {
    white: '#fff',
    blueCyanDarkest: '#263238',
    cyanDark: '#169ead',
    cyanLightest: '#dde5e8',
    grayLight: '#999',
    grayDark: '#626262',
    redDanger: '#dc3545',
    orange: '#f56a00',
  },
  fonts: ['Roboto', 'sans-serif'],
};
