import React from 'react';
import { withFormik } from 'formik';
import { Row, Col } from 'antd';
import FormInput from '../../../../../components/Forms/Input';
import DatePickerField from '../../../../../components/DatePicker';
import { FormProps, IFormValues, IFormProps } from './types';
import {
  Container,
  DateView,
  Button,
  ResetButton,
  Form,
  Label,
} from './styles';

const Filter: React.FC<FormProps> = (props: FormProps) => {
  const {
    handleSubmit,
    resetForm,
  } = props;

  const resetUserForm = async () => {
    resetForm();
    await props.onSubmit({} as IFormValues);
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Label>Filtros:</Label>
        </Row>
        <Row gutter={16}>
          <Col span={6}>
            <FormInput
              type="text"
              name="name"
              label="Nome"
              placeholder="Nome"
            />
          </Col>
          <Col span={6}>
            <FormInput
              type="text"
              name="description"
              label="Descrição"
              placeholder="Descrição"
            />
          </Col>
          <Col span={6}>
            <DateView>
              <DatePickerField
                name="dateStartFrom"
                placeholder="Data início"
                label="Data início (De)"
              />
            </DateView>
          </Col>
          <Col span={6}>
            <DateView>
              <DatePickerField
                name="dateFinishFrom"
                placeholder="Data fim"
                label="Data fim (De)"
              />
            </DateView>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={6}>
            <DateView>
              <DatePickerField
                name="dateStartTo"
                placeholder="Data início"
                label="Data início (Para)"
              />
            </DateView>
          </Col>
          <Col span={6}>
            <DateView>
              <DatePickerField
                name="dateFinishTo"
                placeholder="Data fim"
                label="Data fim (Para)"
              />
            </DateView>
          </Col>
          <Col span={6} />
          <Col span={3}>
            <ResetButton
              onClick={() => resetUserForm()}
            >
              Limpar filtros
            </ResetButton>
          </Col>
          <Col span={3}>
            <Button htmlType="submit">
              Aplicar filtros
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default withFormik<IFormProps, IFormValues>({
  mapPropsToValues: (): IFormValues => ({
    name: '',
    description: '',
    dateStartFrom: '',
    dateFinishFrom: '',
    dateStartTo: '',
    dateFinishTo: '',
  }),
  handleSubmit: (values, { props }) => props.onSubmit({ ...values }),
})(Filter);
