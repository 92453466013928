/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const convertToBase64 = (file: any) => new Promise((resolve, reject) => {
  const fileReader = new FileReader();
  fileReader.readAsDataURL(file);
  fileReader.onload = () => {
    resolve(fileReader.result);
  };
  fileReader.onerror = (error) => {
    reject(error);
  };
});
