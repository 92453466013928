/* eslint-disable max-len */
import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { ActionTypes } from './types';
import { IPagedResult } from '../../../../global/generics';
import { IRepassesFilterProps, ITransferenciaRepassesDto } from '../../../../global/dataTransferObjects/repasses';
import createRepasseService from '../../../../services/repasses';

export const onUpdateLoadingStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_LOADING_STATUS,
  payload: {
    status,
  },
});

export const onUpdateZeroState = (zeroState = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.ZERO_STATE_STATUS,
  payload: {
    zeroState,
  },
});

export const onGetTransferenciasRepasses = (transferenciaRepasses: IPagedResult<ITransferenciaRepassesDto>) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.GET_TRANSFERENCIA_REPASSES,
  payload: {
    transferenciaRepasses: { ...transferenciaRepasses },
  },
});

export const getTransferenciasRepasses = (
  offSet: number,
  pageSize: number,
  filter?: IRepassesFilterProps,
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const repasseService = createRepasseService();
  onUpdateLoadingStatus(true)(dispatch);
  const result = await repasseService.getTransferenciasRepasses(offSet, pageSize, filter);

  if (result.status !== 200) {
    onUpdateLoadingStatus(false)(dispatch);
    onUpdateZeroState(true)(dispatch);
    toast.error(result.message);
    return;
  }
  onUpdateLoadingStatus(false)(dispatch);
  onGetTransferenciasRepasses(result.response)(dispatch);
};

export const createArquivoRemessa = (
  filter?: IRepassesFilterProps,
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const repasseService = createRepasseService();
  onUpdateLoadingStatus(true)(dispatch);
  const result = await repasseService.createArquivoRemessa(filter);

  if (result.status !== 200) {
    toast.error(result.message);
    return;
  }
  toast.success('Arquivo enviado com sucesso!');
  onUpdateLoadingStatus(false)(dispatch);
};
