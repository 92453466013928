import React from 'react';
import { withFormik } from 'formik';
import schema from './schema';
import { IBusinessList, IBusinessListProps } from './types';
import { IAsyncDropdownOptions } from '../../../global/dataTransferObjects/dropdown';

import {
  AsyncBusinessDropdown,
  Form,
} from './styles';

const FormFilter: React.FC<IBusinessList> = (props: any) => {
  const {
    handleSubmit,
    onLoadEmpresas,
    onSaveEmpresa,
  } = props;

  const handleLoadBusinessOptions = async (filter: any, loadOption: any): Promise<IAsyncDropdownOptions> => {
    const options = await onLoadEmpresas(filter, loadOption);
    return ({
      options,
      hasMore: false,
    });
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <AsyncBusinessDropdown
          name="businessName"
          label="Empresa"
          placeholder="Filtre pelo nome da empresa..."
          loadOptions={handleLoadBusinessOptions}
          onChange={onSaveEmpresa}
        />
      </Form>
    </>
  )
}
export default withFormik<IBusinessList, IBusinessListProps>({
  mapPropsToValues: (): IBusinessListProps => ({
    businessName: null,

  }),
  validationSchema: schema,
  handleSubmit: () => {
    // const businessId = values.businessName?.value;
    // const formattedValues = {
    //   ...values,
    //   businessId,
    // };
    // props.onFormSubmit({ ...formattedValues });
  },
})(FormFilter);
