import styled from 'styled-components';
import { Layout } from 'antd';
import { Button as StyledButton } from '../../../styles/buttons'

export const Container = styled(Layout)`
  padding: 0.5rem 1rem;
  gap: 1rem;
  position: relative;

  @media (min-width: 768px) {
    padding: 1rem 2rem;
  }
`;

export const TableContainer = styled.div``;

export const Button = styled(StyledButton)`
  width: unset;
  margin-top: 0;
  align-self: flex-end;
`;

export const CheckboxContainer = styled.div`
  flex: 1;
  flex-direction: column;
`;

export const CheckboxTitle = styled.text`
  font-size: 14px;
  color: black;
`;

export const AlignCheckbox = styled.div`
    justify-content: space-between;
    padding: none;
    margin-top: 2px;
`;

export const WarningMessage = styled.text`
  padding: 5px;
  background-color: #D0342C;
  color: white;
`
