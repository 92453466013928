import React, { useMemo } from 'react';
import { TableContainer } from '../../styles';
import Table, { TableColumn } from '../../../../../components/Table';
import { ITransactionsTable } from '../../types';
import { createFormatProvider } from '../../../../../services/providers/factories';

const LiveTable: React.FC<ITransactionsTable> = (props: ITransactionsTable) => {
  const { liveTransactions } = props;

  const formatProvider = useMemo(() => createFormatProvider(), []);
  const formattedDate = (date: string) => formatProvider.formatDate(Date.parse(date));

  return (
    <TableContainer>
      <Table dataSource={liveTransactions}>
        <TableColumn title="Data/Hora" dataIndex="paymentDate" key="paymentDate" render={formattedDate} />
        <TableColumn title="Tempo" dataIndex="tempo" key="tempo" />
        <TableColumn title="Empresa" dataIndex="empresa" key="empresa" />
        <TableColumn title="Terminal" dataIndex="terminal" key="terminal" />
        <TableColumn title="Autorizacao" dataIndex="authorizationNumber" key="authorizationNumber" />
        <TableColumn title="Bandeira" dataIndex="brand" key="brand" />
        <TableColumn title="No. Cartao" dataIndex="cardNumber" key="cardNumber" />
        <TableColumn title="Tipo" dataIndex="productName" key="productName" />
        <TableColumn title="NSU" dataIndex="nsu" key="nsu" />
        <TableColumn title="Valor" dataIndex="originalValue" key="originalValue" />
        <TableColumn title="Status" dataIndex="status" key="status" />
      </Table>
    </TableContainer>
  )
}

export default LiveTable;
