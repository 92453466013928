import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { ActionTypes } from './types';
import {
  IBusinessPaymentPixSummaryResultDto,
  IRepassesFilterProps,
  IPixPaymentTransferFilterProps,
} from '../../../../global/dataTransferObjects/repasses';
import createRepasseService from '../../../../services/repasses';

export const onUpdateLoadingStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_LOADING_STATUS,
  payload: {
    status,
  },
});

export const onUpdateZeroState = (zeroState = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.ZERO_STATE_STATUS,
  payload: {
    zeroState,
  },
});

export const onGetBusinessPaymentPixTransfer = (
  paymentPixSummaries: IBusinessPaymentPixSummaryResultDto,
) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.GET_PAYMENT_PIX_SUMMARIES,
  payload: {
    paymentPixSummaries: { ...paymentPixSummaries },
  },
});

export const createArquivoRemessa = (
  filter?: IRepassesFilterProps,
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const repasseService = createRepasseService();
  onUpdateLoadingStatus(true)(dispatch);
  const result = await repasseService.createArquivoRemessa(filter);

  if (result.status !== 200) {
    toast.error(result.message);
    return;
  }
  toast.success('Arquivo enviado com sucesso!');
  onUpdateLoadingStatus(false)(dispatch);
};

export const getBusinessPaymentPixTransfer = (
  filter?: IPixPaymentTransferFilterProps,
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const repasseService = createRepasseService();
  onUpdateLoadingStatus(true)(dispatch);
  const result = await repasseService.getBusinessPaymentPixTransfer(filter);

  if (result.status !== 200) {
    onUpdateLoadingStatus(false)(dispatch);
    onUpdateZeroState(true)(dispatch);
    toast.error(result.message);
    return;
  }
  onUpdateLoadingStatus(false)(dispatch);
  onGetBusinessPaymentPixTransfer(result.response)(dispatch);
};

export const makePixPaymentByBs2Async = (
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const repasseService = createRepasseService();
  onUpdateLoadingStatus(true)(dispatch);
  const result = await repasseService.makePixPaymentByBs2Async();

  if (result.status !== 200) {
    toast.error(result.message);
    onUpdateLoadingStatus(false)(dispatch);
    return;
  }
  toast.success('Pagamento enviado com sucesso!');
  onUpdateLoadingStatus(false)(dispatch);
};
