/* eslint-disable @typescript-eslint/no-shadow */
import React, {
  useReducer,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { PlusOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import Table, { TableColumn } from '../../../components/Table';
import CadastroCnpjModal from '../../../components/Forms/Pages/CadastroCnpjModal';
import LoadingOverlay from '../../../components/LoadingOverlay';
import { Reducer, InitialState } from './actions/reducer';
import {
  getUserCnpj,
  onUpdatePaymentModalStatus,
  onOpenPaymentModal,
  createNewConsulta,
} from './actions';
import {
  Container,
  TableContainer,
  Button,
} from './styles';
import { T2 } from '../../../styles/titles';
import { ISelectValue } from '../../../components/Forms/AsyncFormDropdown/types';
import { IConsultaFormValues } from '../../../components/Forms/Pages/CadastroCnpjModal/types';
import createIbgeService from '../../../services/ibge';
import { createFormatProvider } from '../../../services/providers/factories';
import { RoutesEnum } from '../../../routes/types';
import { IConsultaDto } from '../../../global/dataTransferObjects/consulta';

const CadastroCnpj: React.FC = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const [isMouseEnteredRow, setIsMouseEnteredRow] = useState<boolean>(false);

  const fetchUserCadastroCnpj = useCallback(() => getUserCnpj()(dispatch), []);
  const formatProvider = useMemo(() => createFormatProvider(), []);
  const formattedDate = (date: string) => formatProvider.formatDate(Date.parse(date));
  const history = useHistory();

  useEffect(() => {
    fetchUserCadastroCnpj();
  }, []);

  const updatePaymentModalStatus = (status: boolean) => onUpdatePaymentModalStatus(status)(dispatch);

  const handleLoadUfOptions = async (): Promise<ISelectValue[]> => {
    const ibgeService = createIbgeService();
    const response = await ibgeService.getUfsAsync().catch(() => []);

    return ibgeService.mapUfsToSelect(response);
  };

  const handleButtonClick = () => onOpenPaymentModal()(dispatch);

  const onModalFormSubmit = async (values: IConsultaFormValues) => {
    const result = await createNewConsulta(values)(dispatch);
    if (!result) {
      return;
    }

    toast.success('Solicitação cadastrada com sucesso!');
    fetchUserCadastroCnpj();
  };

  const filteredList = state.consultas.records.map((x) => ({
    id: x.id,
    nomeIdentificador: x.nomeIdentificador,
    statusVerificacao: 'Pendente',
    numeroDocumento: x.numeroDocumento,
    dataInicio: x?.dataInicio?.toString(),
    dataFim: x?.dataFim?.toString(),
  }));

  const handleInteractionOnRow = (record: IConsultaDto) => ({
    onClick: () => history.push(`${RoutesEnum.UnidadesRecebiveis}/${record.id}`),
    onMouseEnter: () => setIsMouseEnteredRow(true),
    onMouseLeave: () => setIsMouseEnteredRow(false),
  });

  return (
    <Container>
      <LoadingOverlay
        show={state.isPaymentModalOpen ? false : state.loading}
        relative
      />
      <T2>Cadastrar solicitação de consulta</T2>
      <Button
        icon={<PlusOutlined />}
        onClick={handleButtonClick}
      >
        Cadastrar
      </Button>
      <TableContainer>
        <Table
          dataSource={filteredList}
          onRow={handleInteractionOnRow}
          isMouseEnteredRow={isMouseEnteredRow}
        >
          <TableColumn title="Nome" dataIndex="nomeIdentificador" key="nomeIdentificador" />
          <TableColumn title="CNPJ/CPF" dataIndex="numeroDocumento" key="numeroDocumento" />
          <TableColumn title="Data inicio" dataIndex="dataInicio" key="dataInicio" render={formattedDate} />
          <TableColumn title="Data fim" dataIndex="dataFim" key="dataFim" render={formattedDate} />
          <TableColumn title="Status" dataIndex="statusVerificacao" key="statusVerificacao" />
        </Table>
      </TableContainer>
      <CadastroCnpjModal
        loading={state.loading}
        isVisible={state.isPaymentModalOpen}
        onModalClose={() => updatePaymentModalStatus(false)}
        onLoadUfOptions={handleLoadUfOptions}
        onFormSubmit={onModalFormSubmit}
      />
    </Container>
  );
};

export default CadastroCnpj;
