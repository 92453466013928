import { IPixPaymentRepassDto } from '../../../../global/dataTransferObjects/repasses';

export interface IPageState {
  loading: boolean;
  records: IPixPaymentRepassDto[];
  zeroState: boolean;
  totalOriginalAmount: number;
  totalCostFee: number;
  totalPaymentAmount: number;
}

export enum ActionTypes {
  UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS',
  GET_PAYMENT_REPASSES = 'GET_PAYMENT_REPASSES',
  ZERO_STATE_STATUS = 'ZERO_STATE_STATUS',
}

export interface IAction {
  type: ActionTypes;
  payload?: any;
}
