import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { theme } from './styles/theme';
import Routes from './routes';
import GlobalStyle from './styles/globals';
import store from './store'
import PersistGate from './components/PersistGate';

import 'react-credit-cards/es/styles-compiled.css';

const App: React.FC = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <PersistGate>
        <Routes />
        <GlobalStyle />
      </PersistGate>
      <ToastContainer autoClose={3000} />
    </ThemeProvider>
  </Provider>
)

export default App;
