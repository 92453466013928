import React, {
  useReducer,
  useEffect,
  useMemo,
} from 'react';
import { Link } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';
import FormFilter from './components/filter';
import Table, { TableColumn, TableSum, TableSumCell } from '../../../components/Table';
import { Reducer, InitialState } from './actions/reducer';
import {
  Container,
  TableContainer,
  LegendContainer,
  LegendText,
  LegendTitle,
} from './styles';
import { T2 } from '../../../styles/titles';
import { createFormatProvider } from '../../../services/providers/factories';
import { getBusinessPaymentPixTransfer, makePixPaymentByBs2Async } from './actions';
import { IPixPaymentTransferFilterProps } from '../../../global/dataTransferObjects/repasses';
import LoadingOverlay from '../../../components/LoadingOverlay';

const TransferenciasRepassesPix: React.FC = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);

  useEffect(() => {
    getBusinessPaymentPixTransfer()(dispatch)
  }, []);

  const { format } = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const formatProvider = useMemo(() => createFormatProvider(), []);
  const formattedDate = (date: string) => formatProvider.formatDate(Date.parse(date));

  const handleDetalhesRepassesFilter = async (filter: IPixPaymentTransferFilterProps) => {
    getBusinessPaymentPixTransfer(filter)(dispatch);
  }

  const handleDownloadArquivoRemessa = async () => {
    makePixPaymentByBs2Async()(dispatch);
  }

  const allRepasses = state?.paymentPixSummaries?.summaries.map((params) => (
    {
      ...params,
    }
  ));

  const totalTransferFee = allRepasses?.reduce((sum, params) => sum + (params.transferFee || 0), 0);

  const {
    totalGrossAmount,
    totalPaymentAmount,
    totalCostAmount,
    totalInitPaymentAmount,
  } = state?.paymentPixSummaries;

  return (
    <Container>
      <LoadingOverlay
        show={state.loading}
        relative
      />
      <T2>Transferência de Repasses Pix</T2>
      <FormFilter
        onFormSubmit={handleDetalhesRepassesFilter}
        OnDownloadArquivoRemessa={handleDownloadArquivoRemessa}
      />
      <TableContainer>
        <Table
          dataSource={allRepasses}
          summary={() => (
            <TableSum fixed>
              <TableSum.Row>
                <TableSumCell index={0} />
                <TableSumCell index={1} />
                <TableSumCell index={2} />
                <TableSumCell index={3} />
                <TableSumCell index={4} align="left"><b>{format(totalGrossAmount)}</b></TableSumCell>
                <TableSumCell index={5} align="left"><b>{format(totalCostAmount)}</b></TableSumCell>
                <TableSumCell index={5} align="left"><b>{format(totalInitPaymentAmount)}</b></TableSumCell>
                <TableSumCell index={6} align="left"><b>{format(totalTransferFee)}</b></TableSumCell>
                <TableSumCell index={7} align="left"><b>{format(totalPaymentAmount)}</b></TableSumCell>
              </TableSum.Row>
              <TableSum.Row>
                <TableSumCell index={0} />
                <TableSumCell index={1} />
                <TableSumCell index={2} />
                <TableSumCell index={3} />
                <TableSumCell index={4}><b>Total Bruto do Pagamento</b></TableSumCell>
                <TableSumCell index={5}><b>Total Valor Taxa</b></TableSumCell>
                <TableSumCell index={6}><b>Total Valor Líquido</b></TableSumCell>
                <TableSumCell index={7}><b>Total Tarifa Ted</b></TableSumCell>
                <TableSumCell index={8}><b>Total Liquido do Pagamento</b></TableSumCell>
              </TableSum.Row>
            </TableSum>
          )}
        >
          <TableColumn
            title="Visualizar"
            render={(i: any, e: any) => (state?.paymentPixSummaries?.summaries.length >= 1 ? (
              <Link
                style={{
                  color: 'white',
                  backgroundColor: '#169ead',
                  paddingLeft: 4,
                  paddingRight: 4,
                  paddingBottom: 3,
                  paddingTop: 2,
                  borderRadius: 3,
                }}
                to={`/tecpay/admin/repasses/detalhes-pix?id=${e.businessId}`}
              >
                <EyeOutlined />
              </Link>
            ) : null)}
          />
          <TableColumn
            title="Status"
            dataIndex="pixPaymentStatus"
            key="pixPaymentStatus"
            render={(item: boolean) => ({
              children: <div>{item}</div>,
            })}
          />
          <TableColumn
            title="Data de pagamento"
            dataIndex="paymentDate"
            key="paymentDate"
            render={(item: string) => ({
              children: <div>{formattedDate(item)}</div>,
            })}
          />
          <TableColumn
            title="Empresa"
            dataIndex="businessName"
            key="businessName"
            render={(item: string) => ({
              children: <div>{item}</div>,
            })}
          />
          <TableColumn
            title="Valor Bruto"
            dataIndex="totalGrossAmount"
            key="totalGrossAmount"
            render={(item: number) => ({
              children: <div>{format(item)}</div>,
            })}
          />
          <TableColumn
            title="Valor Taxa"
            dataIndex="totalCostAmount"
            key="totalCostAmount"
            render={(item: number) => ({
              children: <div>{format(item)}</div>,
            })}
          />
          <TableColumn
            title="Valor Líquido"
            dataIndex="totalInitPaymentAmount"
            key="totalInitPaymentAmount"
            render={(item: number) => ({
              children: <div>{format(item)}</div>,
            })}
          />
          <TableColumn
            title="Tarifa Ted"
            dataIndex="transferFee"
            key="transferFee"
            render={(item: number) => ({
              children: <div>{format(item)}</div>,
            })}
          />
          <TableColumn
            title="Valor Liquido do Pagamento"
            dataIndex="totalPaymentAmount"
            key="totalPaymentAmount"
            render={(item: number) => ({
              children: <div>{format(item)}</div>,
            })}
          />
        </Table>
      </TableContainer>
      <LegendContainer>
        <LegendTitle>Legenda: </LegendTitle>
        <LegendText
          color="wheat"
        >
          Não tem conta corrente padrão cadastrada
        </LegendText>
      </LegendContainer>
    </Container>
  );
};

export default TransferenciasRepassesPix;
