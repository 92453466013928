import { createGlobalStyle } from 'styled-components';
import 'antd/dist/antd.css';
import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
  #root {
    height: 100%;
  }

  html, body {
    padding: 0;
    margin: 0;
    border: 0;
    box-sizing: border-box;
  }
`;
