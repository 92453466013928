import React, { useCallback, useEffect, useReducer } from 'react';
import { Col, Row } from 'antd';
import { toast } from 'react-toastify';
import LoadingOverlay from '../../components/LoadingOverlay';
import { IUpdateUserDto } from '../../global/dataTransferObjects/user';
import { T2 } from '../../styles/titles';
import { getUser, UpdateUser, changePasswordCard } from './actions';
import { InitialState, Reducer } from './actions/reducer';
import EditUserForm from './components/EditUserForm';
import { Box, Button, Container } from './styles';
import { IMeuPerfilProps } from './types';
import { IFormValues } from './components/EditUserForm/types'
import { HttpStatus } from '../../services/providers/types';

const MeuPerfil: React.FC<IMeuPerfilProps> = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);

  const fetchUser = useCallback(() => getUser()(dispatch), []);

  useEffect(() => { fetchUser(); }, [fetchUser]);

  const onEditUserFormSubmit = async (userUpdated: IFormValues) => {
    const formattedValues: IUpdateUserDto = {
      ...userUpdated,
    }

    await UpdateUser(userUpdated.id, formattedValues)(dispatch);
    await fetchUser();
  };

  const handleChangePassword = async () => {
    const response = await changePasswordCard()(dispatch);
    if (!response) {
      toast.error('Erro ao gerar link de alteração de senha do cartão');
    }

    const changePasswordUrl = `${response.url}${response.token}`;
    window.location.replace(changePasswordUrl);
  }

  const handleRecoverPassword = async () => {
    const response = await changePasswordCard()(dispatch);
    if (response.status === HttpStatus.NOT_FOUND) {
      toast.warning('Não existe cartão vinculado a esse usuário');
    }
    if (response.status === HttpStatus.CREATED) {
      toast.success('Solicitação de recuperação de senha realizada com sucesso');
    }

    if (response.status !== HttpStatus.CREATED && response.status !== HttpStatus.NOT_FOUND) {
      return toast.warning('Não é possível atender essa solicitação neste momento');
    }
    return '';
  }

  return (
    <Container>
      <LoadingOverlay
        show={state.loading}
        relative
      />
      <T2>Meu perfil</T2>
      <Box>
        <Row align="top" justify="space-around">
          <Col span={8}>
            <EditUserForm
              loading={state.loading}
              user={state.user}
              onSignUpFormSubmit={onEditUserFormSubmit}
            />
          </Col>
          <Col span={12}>
            <T2>Cartão tecpay</T2>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              gap: '1rem',
            }}
            >
              <Button htmlType="button" onClick={handleRecoverPassword}>Recuperar senha</Button>
              <Button htmlType="button" onClick={handleChangePassword}>Alterar senha</Button>
            </div>
          </Col>
        </Row>
      </Box>
    </Container>
  );
};

export default MeuPerfil;
