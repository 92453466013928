import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { ActionTypes } from './types';
import { ILiquidacaoDto, ILiquidacaoFilterProps } from '../../../../global/dataTransferObjects/repasses';
import createRepasseService from '../../../../services/repasses';

export const onUpdateLoadingStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_LOADING_STATUS,
  payload: {
    status,
  },
});

export const onUpdateZeroState = (zeroState = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.ZERO_STATE_STATUS,
  payload: {
    zeroState,
  },
});

export const onGetAllLiquidacao = (liquidacoes: ILiquidacaoDto[]) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.GET_LIQUIDACAO,
  payload: {
    liquidacao: liquidacoes,
  },
});

export const getallLiquidacao = (
  filter?: ILiquidacaoFilterProps,
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const repasseService = createRepasseService();
  onUpdateLoadingStatus(true)(dispatch);
  const result = await repasseService.getAllLiquidacao(filter);
  if (result.status === 500) {
    onUpdateLoadingStatus(false)(dispatch);
    onUpdateZeroState(true)(dispatch);
    toast.error('No momento não podemos listar as liquidações, tente novamente mais tarde!');
    return;
  }
  if (result.status !== 200) {
    onUpdateLoadingStatus(false)(dispatch);
    onUpdateZeroState(true)(dispatch);
    toast.error(result.message);
    return;
  }
  onUpdateLoadingStatus(false)(dispatch);
  if (Array.isArray(result.response)) {
    onGetAllLiquidacao(result.response)(dispatch);
  } else {
    onGetAllLiquidacao([result.response])(dispatch);
  }
};
