import { IBusinessPaymentPixSummaryResultDto } from '../../../../global/dataTransferObjects/repasses';

export interface IPageState {
  loading: boolean;
  paymentPixSummaries: IBusinessPaymentPixSummaryResultDto;
  zeroState: boolean;
}

export enum ActionTypes {
  UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS',
  GET_PAYMENT_PIX_SUMMARIES = 'GET_PAYMENT_PIX_SUMMARIES',
  ZERO_STATE_STATUS = 'ZERO_STATE_STATUS',
}

export interface IAction {
  type: ActionTypes;
  payload?: any;
}
