import React, {
  useReducer,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { useLocation } from 'react-router-dom';
import { format as formatDateFns, parseISO } from 'date-fns'
import Table, { TableColumn, TableSum, TableSumCell } from '../../../components/Table';
import FormFilter from './components/filter';
import { Reducer, InitialState } from './actions/reducer';
import {
  Container,
  TableContainer,
} from './styles';
import { T2 } from '../../../styles/titles';
import { createFormatProvider } from '../../../services/providers/factories';
import { getTransferPixDetails } from './actions';
import { IRepassesFilterProps } from '../../../global/dataTransferObjects/repasses';
import LoadingOverlay from '../../../components/LoadingOverlay';
import createRepasseService from '../../../services/repasses';

const DetalhesRepassesPix: React.FC = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const [filterOrder, setFilterOrder] = useState<any>();
  const [statisticBusiness, setStatisticBusiness] = useState<any>({});

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  let businessId: any = query.get('id');

  const repasseService = createRepasseService();

  const getEmpresas = useCallback(async () => {
    const allBusiness = await repasseService.getStatisticsBusiness(businessId);
    const res = allBusiness.response.records.shift();
    setStatisticBusiness(res);
  }, []);

  useEffect(() => {
    getEmpresas();
  }, [getEmpresas]);

  const { format } = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const formatProvider = useMemo(() => createFormatProvider(), []);
  const formattedDate = (date: string) => formatProvider.formatDate(Date.parse(date));

  const formatDateAndHours = (date: string) => {
    const dateFormat = parseISO(date);
    const formattedDateAndHours = formatDateFns(dateFormat, "dd/MM/yyyy' às 'HH:mm'h'");
    return formattedDateAndHours;
  }

  useEffect(() => {
    getTransferPixDetails(filterOrder, businessId)(dispatch);
  }, []);

  const handleDetalhesRepassesFilter = async (filter: IRepassesFilterProps) => {
    businessId = filter?.businessId || businessId;
    setFilterOrder(filter);
    getTransferPixDetails(filter, businessId)(dispatch);
  }

  const formatFee = (value: number) => {
    const feeFormated = Math.abs(value);
    const fee = `${feeFormated.toFixed(2)}%`;
    return fee;
  }

  const allPixTransfer = state?.records?.map((params) => (
    {
      ...params,
    }
  ));

  const formatColor = (amount: number) => {
    if (amount > (statisticBusiness.averageTransation + statisticBusiness.stDevTransaction)) {
      return '#D0342C';
    }
    return '';
  }

  return (
    <Container>
      <LoadingOverlay
        show={state.loading}
        relative
      />
      <T2>Repasses PIX - Detalhes</T2>
      <FormFilter onFormSubmit={handleDetalhesRepassesFilter} />
      <TableContainer>
        <Table
          dataSource={allPixTransfer}
          summary={() => (
            <TableSum fixed>
              <TableSum.Row>
                <TableSumCell index={0} />
                <TableSumCell index={1} />
                <TableSumCell index={2} />
                <TableSumCell index={3} />
                <TableSumCell index={4} />
                <TableSumCell index={5}>
                  <b>{format(state?.totalOriginalAmount)}</b>
                </TableSumCell>
                <TableSumCell index={6} />
                <TableSumCell index={7}>
                  <b>{format(state?.totalCostFee)}</b>
                </TableSumCell>
                <TableSumCell index={8}>
                  <b>{format(state?.totalPaymentAmount)}</b>
                </TableSumCell>

                <TableSumCell index={9} />
                <TableSumCell index={10} />
              </TableSum.Row>
              <TableSum.Row>
                <TableSumCell index={0} />
                <TableSumCell index={1} />
                <TableSumCell index={2} />
                <TableSumCell index={3} />
                <TableSumCell index={4} />
                <TableSumCell index={5}><b>Venda</b></TableSumCell>
                <TableSumCell index={6} />
                <TableSumCell index={7}><b>Valor Taxa</b></TableSumCell>
                <TableSumCell index={8}><b>Vl. Pgto</b></TableSumCell>
                <TableSumCell index={9} />
                <TableSumCell index={10} />
              </TableSum.Row>
            </TableSum>
          )}
        >
          <TableColumn title="Previsão" dataIndex="paymentDate" key="paymentDate" render={formattedDate} />
          <TableColumn title="Empresa" dataIndex="businessName" key="businessName" />
          <TableColumn
            title="Data transação"
            dataIndex="processingDateTime"
            key="processingDateTime"
            render={formatDateAndHours}
          />
          <TableColumn title="TxId" dataIndex="shortId" key="shortId" />
          <TableColumn title="ID App Pix " dataIndex="appPixId" key="appPixId" />
          <TableColumn
            title="Venda"
            dataIndex="grossAmount"
            key="grossAmount"
            render={(item: number) => ({
              props: {
                style: { background: formatColor(item) },
              },
              children: <div>{format(item)}</div>,
            })}
          />
          <TableColumn title="Taxa" dataIndex="fee" key="fee" render={formatFee} />
          <TableColumn
            title="Valor Taxa"
            dataIndex="costFee"
            key="costFee"
            render={(item: number) => ({
              props: {
                style: { background: formatColor(item) },
              },
              children: <div>{format(item)}</div>,
            })}
          />
          <TableColumn title="Vl. Pagto" dataIndex="paymentAmount" key="paymentAmount" render={format} />
          <TableColumn title="Dt. Pgto." dataIndex="paymentDate" key="paymentDate" render={formattedDate} />
          <TableColumn title="Status" dataIndex="pixPaymentStatus" key="pixPaymentStatus" />
        </Table>
      </TableContainer>
    </Container>
  );
};

export default DetalhesRepassesPix;
