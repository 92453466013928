import { IPagedResult } from '../../global/generics';
import { IRepasseService } from '../@types/services';
import {
  HttpMethod,
  IApiResponse, ITecpayApi,
} from '../providers/types';
import {
  IAllRepasses,
  IRepassesFilterProps,
  IDetalhesRepassesFilterProps,
  IDetailsRepasseDto,
  ITransferenciaRepassesDto,
  IStatisticsBusinessDto,
  ILiquidacaoFilterProps,
  ILiquidacaoDto,
  IPixPaymentTransferFilterProps,
  IBusinessPaymentPixSummaryResultDto,
  IPixPaymentRepassSummaryDto,
} from '../../global/dataTransferObjects/repasses';
import { IAppState } from '../../store/types';

const repassesService = (apiProvider: ITecpayApi): IRepasseService => {
  const getAllRepasses = async (
    offset: number,
    pageSize: number,
    filter?: IRepassesFilterProps,
    userStateCompany?: IAppState,
  ): Promise<IApiResponse<IPagedResult<IAllRepasses>>> => {
    const startDate = filter?.startDate || null;
    const finishDate = filter?.finishDate || null;
    const businessId = filter?.businessId || null;
    if (userStateCompany?.user.userInfo.userType !== 3 && userStateCompany?.company.selectedCompany.id.length !== 0) {
      const response = await apiProvider
        .createRequest<IPagedResult<IAllRepasses>>({ url: 'transacoes/repasses' })
        .withSearch({
          offset,
          pageSize,
          startDate,
          finishDate,
          businessId: userStateCompany?.company.selectedCompany.id,
        })
        .sendAsync();

      return response;
    }
    const response = await apiProvider
      .createRequest<IPagedResult<IAllRepasses>>({ url: 'transacoes/repasses' })
      .withSearch({
        offset,
        pageSize,
        startDate,
        finishDate,
        businessId,
      })
      .sendAsync();

    return response;
  };

  const getDetalhesRepasses = async (
    offset: number,
    pageSize: number,
    businessIdentification: string,
    filter?: IDetalhesRepassesFilterProps,
  ): Promise<IApiResponse<IPagedResult<IDetailsRepasseDto>>> => {
    const startDate = filter?.startDate || null;
    const finishDate = filter?.finishDate || null;
    const businessId = businessIdentification || filter?.businessId || null;
    const onlyPayed = filter?.onlyPayed || false;
    const onlyNotPayed = filter?.onlyNotPayed || false;
    const productType = filter?.productType || null;
    const response = await apiProvider
      .createRequest<IPagedResult<IDetailsRepasseDto>>({ url: 'transacoes/repasses/detalhes' })
      .withSearch({
        offset,
        pageSize,
        startDate,
        finishDate,
        businessId,
        onlyPayed,
        onlyNotPayed,
        productType,
      })
      .sendAsync();

    return response;
  };

  const getTransferenciasRepasses = async (
    offset: number,
    pageSize: number,
    filter?: IDetalhesRepassesFilterProps,
  ): Promise<IApiResponse<IPagedResult<ITransferenciaRepassesDto>>> => {
    const startDate = filter?.startDate || null;
    const finishDate = filter?.finishDate || null;
    const response = await apiProvider
      .createRequest<IPagedResult<ITransferenciaRepassesDto>>({ url: 'transacoes/repasses/transferencias' })
      .withSearch({
        offset,
        pageSize,
        startDate,
        finishDate,
      })
      .sendAsync();

    return response;
  };

  const getStatisticsBusiness = async (
    businessId: string,
  ): Promise<IApiResponse<IPagedResult<IStatisticsBusinessDto>>> => {
    const response = await apiProvider
      .createRequest<IPagedResult<IStatisticsBusinessDto>>({ url: 'transacoes/repasses/transferencias/estatisticas' })
      .withSearch({
        businessId,
      })
      .sendAsync();

    return response;
  };

  const createArquivoRemessa = async (
    requestDto: IRepassesFilterProps,
  ): Promise<IApiResponse<void>> => {
    const PaidDateFrom = requestDto?.startDate || null;
    const response = await apiProvider
      .createRequest<void>({ url: '/transacoes/arquivo-remessa' })
      .withMethod(HttpMethod.POST)
      .withSearch({
        PaidDateFrom,
      })
      .sendAsync();

    return response;
  };

  const getAllLiquidacao = async (
    filter?: ILiquidacaoFilterProps,
  ): Promise<IApiResponse<ILiquidacaoDto>> => {
    const startDate = filter?.startDate || null;
    const finishDate = filter?.finishDate || null;
    const response = await apiProvider
      .createRequest<ILiquidacaoDto>({ url: 'transacoes/liquidacao' })
      .withSearch({
        startDate,
        finishDate,
      })
      .sendAsync();

    return response;
  };

  const getBusinessPaymentPixTransfer = async (
    filter?: IPixPaymentTransferFilterProps,
  ): Promise<IApiResponse<IBusinessPaymentPixSummaryResultDto>> => {
    const startDate = filter?.startDate || null;
    const finishDate = filter?.finishDate || null;
    const response = await apiProvider
      .createRequest<IBusinessPaymentPixSummaryResultDto>({ url: 'pix-payments' })
      .withSearch({
        startDate,
        finishDate,
      })
      .sendAsync();

    return response;
  };

  const getTransferPixDetails = async (
    filter?: IPixPaymentTransferFilterProps,
    businessIdentification?: string,
  ): Promise<IApiResponse<IPixPaymentRepassSummaryDto>> => {
    const startDate = filter?.startDate || null;
    const finishDate = filter?.finishDate || null;
    const businessId = businessIdentification || filter?.businessId || null;
    const response = await apiProvider
      .createRequest<IPixPaymentRepassSummaryDto>({ url: 'pix-payments/transactions' })
      .withSearch({
        startDate,
        finishDate,
        businessId,
      })
      .sendAsync();

    return response;
  };

  const makePixPaymentByBs2Async = async (
  ): Promise<IApiResponse<void>> => {
    const response = await apiProvider
      .createRequest<void>({ url: '/pix-payments/make-payment' })
      .withMethod(HttpMethod.POST)
      .sendAsync();

    return response;
  };

  return ({
    getAllLiquidacao,
    createArquivoRemessa,
    getAllRepasses,
    getDetalhesRepasses,
    getTransferenciasRepasses,
    getStatisticsBusiness,
    getBusinessPaymentPixTransfer,
    getTransferPixDetails,
    makePixPaymentByBs2Async,
  });
};

export default repassesService;
