import styled from 'styled-components'
import {
  Modal as AntdModal,
  Row as AntdRow,
  Col as AntdCol,
  Radio as AntdRadio,
} from 'antd';
import { Button as StyledButton } from '../../../../../styles/buttons';

const AntdRadioGroup = AntdRadio.Group;

export const Modal = styled(AntdModal)``;

export const Form = styled.form``;

export const Row = styled(AntdRow).attrs({
  align: 'middle',
  justify: 'center',
})``;

export const Col = styled(AntdCol).attrs({
  lg: {
    span: 21,
  },
  span: 24,
})`
 display: flex;
 flex-direction: column;
`;

export const Button = styled(StyledButton)``;

export const Radio = styled(AntdRadio)``;

export const RadioGroup = styled(AntdRadioGroup)`
    margin-bottom: 0.8rem;
    text-align: center;
    width: 100%;
`;

export const Warning = styled.span`
  margin-top: 0.4rem;
  color: ${(props) => props.theme.colors.redDanger};
`;

export const Space = styled.div`
  height: 20px;
`;

export const Description = styled.text`
  font-size: 16px;
  font-weight: bold;
  color: black;
`;

export const Item = styled.text`
  margin-left: 10px;
  font-size: 16px;
  color: black;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const TextGroup = styled.div`
  margin-bottom: 10px;
`;
