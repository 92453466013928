import React from 'react';
import { withFormik } from 'formik';
import { Row, Col } from 'antd';
import Popconfirm from 'antd/es/popconfirm';
import schema from './schema';
import DatePickerField from '../../../../../components/DatePicker';
import { IFilterDetalheRepasse, IFilterDetalheRepasseProps } from './types';
import FormInput from '../../../../../components/Forms/Input';
import useGetCompany from '../../../../../hooks/useCurrentBusiness';
import { IAsyncDropdownOptions } from '../../../../../global/dataTransferObjects/dropdown';

import {
  DateView,
  Button,
  ResetButton,
  Form,
  Label,
} from './styles';
import AsyncFormDropdown from '../../../../../components/Forms/AsyncFormDropdown';

const FormFilter: React.FC<IFilterDetalheRepasse> = (props: any) => {
  const {
    handleSubmit,
    resetForm,
    onLoadEmpresas,
    stateUserCompany,
    OnDownloadArquivoRemessa,
    values,
  } = props;
  const resetFormTransaction = async () => {
    await resetForm();
    await props.onFormSubmit({});
  }
  const business = useGetCompany();
  const handleLoadBusinessOptions = async (filter: any, loadOption: any): Promise<IAsyncDropdownOptions> => {
    const options = await onLoadEmpresas(filter, loadOption);
    return ({
      options,
      hasMore: false,
    });
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Label>Filtros:</Label>
        </Row>
        <Row
          gutter={16}
        >
          <Col span={6}>
            <DateView>
              <DatePickerField
                name="startDate"
                placeholder="Data inicio"
                label="De"
              />
            </DateView>
          </Col>
          <Col span={6}>
            <DateView>
              <DatePickerField
                name="finishDate"
                placeholder="Data fim"
                label="Até"
              />
            </DateView>
          </Col>
          <Col span={12}>
            {stateUserCompany?.user.userInfo.userType === 3
            && (
              <AsyncFormDropdown
                name="businessName"
                label="Empresa"
                placeholder="Filtre pelo nome da empresa..."
                values={business.company.selectedCompany.name}
                loadOptions={handleLoadBusinessOptions}
              />
            )}
            {stateUserCompany?.user.userInfo.userType !== 3
            && (
              <FormInput
                type="string"
                name="empresa"
                label="Empresa"
                disabled
                value={stateUserCompany?.company.selectedCompany.name}
              />
            )}

          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Button htmlType="submit">
              Aplicar filtros
            </Button>
          </Col>
          <Col span={2} />
          <Col span={6}>
            <ResetButton
              onClick={() => resetFormTransaction()}
            >
              Limpar filtros
            </ResetButton>
          </Col>
          <Col span={6} />
          {OnDownloadArquivoRemessa && (
            <Popconfirm
              title="Essa operação não pode ser desfeita, deseja enviar remessa para pagamento?"
              cancelText="Cancelar"
              okText="Enviar"
              onConfirm={() => OnDownloadArquivoRemessa(values)}
            >
              <Col span={4}>
                <Button>
                  Enviar remessa ftp
                </Button>
              </Col>
            </Popconfirm>
          )}
        </Row>
      </Form>
    </>
  )
}
export default withFormik<IFilterDetalheRepasse, IFilterDetalheRepasseProps>({
  mapPropsToValues: (): IFilterDetalheRepasseProps => ({
    businessName: null,
    startDate: '',
    finishDate: '',
    onlyPayed: false,
    onlyNotPayed: false,
  }),
  validationSchema: schema,
  handleSubmit: (values, { props }) => {
    const businessId = values.businessName?.value;
    const formattedValues = {
      ...values,
      businessId,
    };
    props.onFormSubmit(formattedValues);
  },
})(FormFilter);
