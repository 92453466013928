import { IArquivoRetornoService } from '../@types/services';
import { createApi } from '../providers/factories';
import arquivoRetornoService from './arquivoRetorno';

const createArquivoRetornoService = (): IArquivoRetornoService => {
  const apiProvider = createApi();
  return arquivoRetornoService(apiProvider);
};

export default createArquivoRetornoService;
