import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { ActionTypes } from './types';
import { IRepassesFilterProps, IPixPaymentRepassSummaryDto } from '../../../../global/dataTransferObjects/repasses';
import createRepasseService from '../../../../services/repasses';

export const onUpdateLoadingStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_LOADING_STATUS,
  payload: {
    status,
  },
});

export const onUpdateZeroState = (zeroState = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.ZERO_STATE_STATUS,
  payload: {
    zeroState,
  },
});

export const onGetTransferPixDetails = (summary: IPixPaymentRepassSummaryDto) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.GET_PAYMENT_REPASSES,
  payload: {
    records: summary.transactions,
    totalOriginalAmount: summary.totalOriginalAmount,
    totalCostFee: summary.totalCostFee,
    totalPaymentAmount: summary.totalPaymentAmount,
  },
});

export const getTransferPixDetails = (
  filter?: IRepassesFilterProps,
  businessId?: string,
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const repasseService = createRepasseService();
  onUpdateLoadingStatus(true)(dispatch);
  const result = await repasseService.getTransferPixDetails(filter, businessId);

  if (result.status !== 200) {
    onUpdateLoadingStatus(false)(dispatch);
    onUpdateZeroState(true)(dispatch);
    toast.error(result.message);
    return;
  }
  onUpdateLoadingStatus(false)(dispatch);
  // const records = Array.isArray(result.response) ? result.response : [result.response];
  onGetTransferPixDetails(result.response)(dispatch);
};
