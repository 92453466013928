import styled from 'styled-components';
import {
  Layout as AntdLayout,
  Avatar as AntdAvatar,
  Dropdown as AntdDropdown,
  Menu as AntdMenu,
} from 'antd';
import { darken } from 'polished';
import { Link as RouterLink } from 'react-router-dom'
import logoImage from '../../assets/tecpay-no-bg.png';

const {
  Header: AntdHeader,
  Sider: AntdSider,
  Content: AntContent,
} = AntdLayout;

const { Item: AntdItem, SubMenu: AntdSubMenu } = AntdMenu;

export const Layout = styled(AntdLayout)``;

export const Header = styled(AntdHeader)`
  background: ${(props) => props.theme.colors.white};
  padding: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .trigger {
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .trigger:hover {
    color: ${(props) => props.theme.colors.cyanDark};
  }
`;

export const Sider = styled(AntdSider)``;

export const Avatar = styled(AntdAvatar).attrs({
  size: 'large',
})`
  background-color: ${(props) => props.theme.colors.orange};
  vertical-align: middle;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${(props) => `${darken(0.2, props.theme.colors.orange)}`};
  }
`;

export const Dropdown = styled(AntdDropdown)``;

export const Link = styled(RouterLink)``;

export const Logo = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TecpayLogo = styled.img.attrs({ src: logoImage })`
  margin: 20px;
  height: auto;
  width: 100%;
  max-width: 120px;
`;

export const Content = styled(AntContent)``;

export const Menu = styled(AntdMenu)``;

export const Item = styled(AntdItem)``;

export const AvatarOverlayItem = styled(Item)`
  font-weight: 500;
`;

export const SubMenu = styled(AntdSubMenu)``;
