import React from 'react';
import {
  BarcodeOutlined,
  CarOutlined, CarryOutOutlined, FileSearchOutlined, QrcodeOutlined,
} from '@ant-design/icons';
import { BiCopy, BiTransfer } from 'react-icons/bi';
import { BsBoxArrowUp, BsCardList } from 'react-icons/bs';
import { VscLiveShare } from 'react-icons/vsc';
import { MdOutlineAccountBalanceWallet, MdOutlineRequestPage } from 'react-icons/md';
import { RiFolderTransferLine } from 'react-icons/ri';
import { AiFillCreditCard } from 'react-icons/ai';
import { FaRegCreditCard } from 'react-icons/fa';
import {
  Sider,
  Logo,
  TecpayLogo,
  Menu,
  SubMenu,
  Item,
  Link,
} from '../styles';
import { ISideMenuProps } from '../types';
import { RoutesEnum } from '../../../routes/types';

const SideMenu: React.FC<ISideMenuProps> = (props: ISideMenuProps) => {
  const { collapsed } = props;
  return (
    <Sider
      width={200}
      className="site-layout-background"
      trigger={null}
      collapsible
      collapsed={collapsed}
    >
      <Logo to={RoutesEnum.Dashboard}>
        <TecpayLogo alt="Dashboard" />
      </Logo>
      <Menu
        mode="inline"
        theme="dark"
      >
        <SubMenu key="conta-digital" title="Conta Digital" icon={<MdOutlineAccountBalanceWallet />}>
          <Item icon={<FileSearchOutlined />} key="conta-digital-1">
            <Link to="/conta-digital/extrato">
              Extrato
            </Link>
          </Item>
        </SubMenu>
        <SubMenu key="boletos" title="Boleto" icon={<BarcodeOutlined />}>
          <Item key="boletos-1" icon={<MdOutlineRequestPage />}>
            <Link to="/boletos/pagamentos">
              Pagamentos
            </Link>
          </Item>
        </SubMenu>
        <SubMenu key="repasses" title="Transações e Repasses" icon={<BiTransfer />}>
          <Item icon={<RiFolderTransferLine />} key="repasses">
            <Link to="/repasses">
              Repasses
            </Link>
          </Item>
          <Item icon={<VscLiveShare />} key="live">
            <Link to="/transacoes-tempo-real">
              Transações em Tempo Real
            </Link>
          </Item>
          <Item icon={<BsCardList />} key="detail">
            <Link to="/transacoes-repasse">
              Detalhes
            </Link>
          </Item>
        </SubMenu>
        <SubMenu key="veiculos" title="Veículos" icon={<CarOutlined />}>
          <Item key="1">
            <Link to="/veiculos">
              Meus Veículos
            </Link>
          </Item>
        </SubMenu>
        <SubMenu key="debitos-veiculares" title="Debitos Veiculares" icon={<CarryOutOutlined />}>
          <Item key="2">
            <Link to="/debitos-veiculares/pagamentos">
              Meus Pagamentos
            </Link>
          </Item>
          <Item key="3">
            <Link to="/debitos-veiculares/debitos">
              Débitos
            </Link>
          </Item>
          <Item key="4">
            <Link to="/debitos-veiculares/multas-renainf">
              Multas Renainf
            </Link>
          </Item>
          <Item icon={<BiTransfer />} key="5">
            <Link to="/transacoes-repasse">
              Transferências e Repasses
            </Link>
          </Item>
        </SubMenu>
        <SubMenu key="pix" title="Pix" icon={<QrcodeOutlined />}>
          <Item key="pix-1" icon={<BiCopy />}>
            <Link to="/pix/copia-e-cola">
              Recebimento
            </Link>
          </Item>
          <Item key="pix-2" icon={<BsBoxArrowUp />}>
            <Link to="/pix/pagamentos">
              Pagamentos
            </Link>
          </Item>
        </SubMenu>
        <SubMenu key="cartao" title="Cartão" icon={<AiFillCreditCard />}>
          <Item icon={<FaRegCreditCard />} key="conta-digital-2">
            <Link to="/conta-digital/carga-cartao">
              Recarregar Cartão
            </Link>
          </Item>
          <Item icon={<FileSearchOutlined />} key="conta-digital-2-extrato">
            <Link to="/conta-digital/extrato-cartao">
              Extrato Cartão
            </Link>
          </Item>
        </SubMenu>
      </Menu>
    </Sider>
  );
};

export default SideMenu;
