import styled from 'styled-components';
import { Layout } from 'antd';
import { Button as StyledButton } from '../../../styles/buttons'

interface styledSubtitle {
  color?: string;
}

export const Container = styled(Layout)`
  padding: 0.5rem 1rem;
  gap: 1rem;
  position: relative;

  @media (min-width: 768px) {
    padding: 1rem 2rem;
  }
`;

export const TableContainer = styled.div``;

export const Button = styled(StyledButton)`
  width: unset;
  margin-top: 0;
  align-self: flex-end;
`;

export const PredictedValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const ItemTable = styled.text`
  margin-right: 10px;
`;

export const LegendContainer = styled.div``;

export const LegendTitle = styled.text``;

export const LegendText = styled.text<styledSubtitle>`
  padding: 5px;
  background: ${({ color }) => (color || 'red')};
`;

export const Description = styled.text`
  font-size: 16px;
  font-weight: bold;
  color: black;
`;

export const Item = styled.text`
  margin-left: 10px;
  font-size: 16px;
  color: black;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const TextGroup = styled.div`
  margin-bottom: 10px;
`;
