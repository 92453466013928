import styled from 'styled-components';
import { Typography } from 'antd';

const { Title } = Typography;

export const T2 = styled(Title).attrs({
  level: 2,
})``;

export const T3 = styled(Title).attrs({
  level: 3,
})``;

export const Separator = styled.div`
  border-bottom: 2px solid #dedede;
  width: 100%;
`;
