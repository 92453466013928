import { IPixPaymentRepassDto } from '../../../../global/dataTransferObjects/repasses';
import { ActionTypes, IAction, IPageState } from './types';

export const InitialState: IPageState = {
  loading: false,
  zeroState: false,
  records: [] as IPixPaymentRepassDto[],
  totalOriginalAmount: 0,
  totalCostFee: 0,
  totalPaymentAmount: 0,
};

export const Reducer = (state: IPageState, action: IAction): IPageState => {
  switch (action.type) {
    case ActionTypes.UPDATE_LOADING_STATUS:
      return {
        ...state,
        loading: action.payload?.status ?? false,
      };
    case ActionTypes.GET_PAYMENT_REPASSES:
      return {
        ...state,
        loading: false,
        records: action.payload?.records ?? [],
        totalOriginalAmount: action.payload?.totalOriginalAmount ?? 0,
        totalCostFee: action.payload?.totalCostFee ?? 0,
        totalPaymentAmount: action.payload?.totalPaymentAmount ?? 0,
      };
    case ActionTypes.ZERO_STATE_STATUS:
      return {
        ...state,
        zeroState: action.payload?.zeroState ?? false,
      };
    default:
      return { ...state };
  }
};
