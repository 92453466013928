import { ActionTypes, IPageState, IAction } from './types';

export const InitialState: IPageState = {
  loading: false,
  zeroState: false,
  liquidacao: [],
};

export const Reducer = (state: IPageState, action: IAction): IPageState => {
  switch (action.type) {
    case ActionTypes.UPDATE_LOADING_STATUS:
      return {
        ...state,
        loading: action.payload?.status ?? false,
      };
    case ActionTypes.GET_LIQUIDACAO:
      return {
        ...state,
        liquidacao: action.payload?.liquidacao ?? [],
      };
    case ActionTypes.ZERO_STATE_STATUS:
      return {
        ...state,
        zeroState: action.payload?.zeroState ?? false,
      };
    default:
      return state;
  }
};
