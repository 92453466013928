import { ActionTypes, IAction, IPageState } from './types';

export const InitialState: IPageState = {
  loading: false,
  zeroState: false,
  isViewArquivoRetornorModalOpen: false,
  arquivoRetorno: {
    count: 0,
    offset: 0,
    records: [],
    pageSize: 20,
  },
  detalhe: [
    {
      identificacaoLinhaDetalhe: '',
      numeroSequencialLinhaDetalhe: 0,
      dataOperacao: '',
      remetenteOrigem: '',
      codigoAgenciaBancariaDebito: '',
      numeroContaDebito: '',
      valorPagamento: 0,
      codigoBancoCredito: '',
      agenciaBancariaCredito: '',
      numeroContaCredito: '',
      numeroIdentificacaoBeneficiario: '',
      nomeBeneficiario: '',
      codigoFinalidade: '',
      codigoRetorno: '',
      numeroSequencialLinhaDetalheFim: 0,
      codigoCredenciadora: 0,
      tipoConta: '',
      filler: '',
    },
  ],
};

export const Reducer = (state: IPageState, action: IAction): IPageState => {
  switch (action.type) {
    case ActionTypes.UPDATE_LOADING_STATUS:
      return {
        ...state,
        loading: action.payload?.status ?? false,
      };
    case ActionTypes.GET_ARQUIVO_RETORNO:
      return {
        ...state,
        loading: false,
        arquivoRetorno: action.payload?.arquivoRetorno ?? { ...InitialState.arquivoRetorno },
      };
    case ActionTypes.ZERO_STATE_STATUS:
      return {
        ...state,
        zeroState: action.payload?.zeroState ?? false,
      };
    case ActionTypes.VIEW_ARQUIVO_RETORNO_MODAL_STATUS:
      return {
        ...state,
        isViewArquivoRetornorModalOpen: action.payload.status ?? false,
      };
    case ActionTypes.SELECT_ARQUIVO_RETORNO_DETALHES:
      return {
        ...state,
        detalhe: action.payload.detalhe,
        isViewArquivoRetornorModalOpen: true,
      };
    default:
      return { ...state };
  }
};
