import { combineReducers } from 'redux';

import UserReducer from './user/reducer';
import CompanyReducer from './Company/reducer';

const rootReducer = combineReducers({
  user: UserReducer,
  company: CompanyReducer,
});

export default rootReducer;
