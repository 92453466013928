import React, {
  useReducer,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { Link } from 'react-router-dom';
import { createFormatProvider } from '../../services/providers/factories';
import { updateContaDigital, updateSaldoCartao } from './actions';
import { InitialState, Reducer } from './actions/reducer';
import { Card, Container, Meta } from './styles';

const Dashboard: React.FC = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const formatProvider = useMemo(() => createFormatProvider(), []);
  const fetchContaDigital = useCallback(() => updateContaDigital()(dispatch), []);
  const fetchSaldoCartao = useCallback(() => updateSaldoCartao()(dispatch), []);

  useEffect(() => {
    fetchContaDigital();
    fetchSaldoCartao();
  }, []);

  const formattedValue = useMemo(() => (value: number) => (value !== undefined
    ? formatProvider.formatPrice(value)
    : formatProvider.formatPrice(0)), []);

  return (
    <Container>
      <Card
        title="Resumo da sua conta"
        loading={state.loading}
        actions={[<Link to="/conta-digital/extrato">Extrato</Link>]}
      >
        <Meta title="Saldo da conta digital:" description={formattedValue(state.contaDigital.balance)} />
        <Meta title="Saldo do cartão:" description={formattedValue(state.saldoCartao.valorDeSaldo)} />
      </Card>
    </Container>
  )
};

export default Dashboard;
