import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { ActionTypes } from './types';
import { IPagedResult } from '../../../../global/generics';
import { IArquivoRetornoFilterProps } from '../../../../global/dataTransferObjects/repasses';
import createArquivoRetornoService from '../../../../services/arquivoRetorno';
import { AdiqArquivoPagamentoRetorno, Detalhe } from '../../../../global/dataTransferObjects/arquivoRetorno';

export const onUpdateLoadingStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_LOADING_STATUS,
  payload: {
    status,
  },
});

export const onUpdateZeroState = (zeroState = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.ZERO_STATE_STATUS,
  payload: {
    zeroState,
  },
});

export const onGetArquivoRetornoBs2 = (arquivoRetorno: IPagedResult<AdiqArquivoPagamentoRetorno>) => (
  dispatch: Dispatch<any>,
): void => dispatch({
  type: ActionTypes.GET_ARQUIVO_RETORNO,
  payload: {
    arquivoRetorno: { ...arquivoRetorno },
  },
});

export const onViewArquivoRetornoModalStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.VIEW_ARQUIVO_RETORNO_MODAL_STATUS,
  payload: { status },
});

export const onSelectArquivoRetornoDetalhes = (detalhe: Detalhe[]) => (dispatch: Dispatch<any>): void => {
  dispatch({
    type: ActionTypes.SELECT_ARQUIVO_RETORNO_DETALHES,
    payload: { detalhe },
  });
};

export const getArquivoRetornoBs2 = (
  offSet: number,
  pageSize: number,
  filter?: IArquivoRetornoFilterProps,
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const arquivoRetornoService = createArquivoRetornoService();
  onUpdateLoadingStatus(true)(dispatch);
  const result = await arquivoRetornoService.getArquivoRetornoBs2(offSet, pageSize, filter);

  if (result.status !== 200) {
    onUpdateLoadingStatus(false)(dispatch);
    onUpdateZeroState(true)(dispatch);
    toast.error(result.message);
    return;
  }
  onUpdateLoadingStatus(false)(dispatch);
  onGetArquivoRetornoBs2(result.response)(dispatch);
};
