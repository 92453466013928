import React from 'react';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import useCurrentUser from '../../../hooks/useCurrentUser';
import UserAvatar from './UserAvatar';
import { Header } from '../styles';
import { IHeaderMenuProps } from '../types';
import createBusinessLinked from '../../../services/companyLikend';
import { ISelectValue } from '../../Forms/AsyncFormDropdown/types';
import FormFilter from '../../Forms/SelectEmpresas';
import { saveCompanySelected } from '../../../store/Company';
import { ISelectedBusiness } from '../../../global/dataTransferObjects/business';

const HeaderMenu: React.FC<IHeaderMenuProps> = (props: IHeaderMenuProps) => {
  const {
    onSignOutButtonClick,
    collapsed,
    toggle,
    onSaveCompanySelected,
    onModalStatus,
  } = props;
  const { name } = useCurrentUser();

  const businessListService = createBusinessLinked()

  const handleLoadBusinessOptions = async (): Promise<Array<any>> => {
    const allBusiness: any = await businessListService.getCompanyLinkedList();
    const options = allBusiness.response.map((item: any) => ({
      value: item.id,
      label: item.name,
    }))
    return options;
  }

  const handleCompanySelected = (item: ISelectValue) => {
    if (item !== undefined) {
      const selectedCompany: ISelectedBusiness = {
        id: item.value,
        name: item.label,
      }
      onSaveCompanySelected(selectedCompany);
    }
  }

  return (
    <Header>
      {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
        className: 'trigger',
        onClick: toggle,
      })}
      <FormFilter
        onSaveEmpresa={handleCompanySelected}
        onLoadEmpresas={handleLoadBusinessOptions}
      />
      <UserAvatar
        userName={name}
        onSignOutButtonClick={onSignOutButtonClick}
        onModalStatus={onModalStatus}
      />
    </Header>
  );
};

const mapStateToProps = (props: IHeaderMenuProps) => ({
  props,
});

const mapDispatchToProps = {
  onSaveCompanySelected: saveCompanySelected,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMenu);
