import { IPhobeusPagedResult } from '../../global/generics';
import { IApiResponse, ITecpayApi } from '../providers/types';
import { IAllLiveTransactions, ILiveTransactionsFilterProps } from '../../global/dataTransferObjects/transacoesTempoReal';

const liveTransactionService = (apiProvider: ITecpayApi): any => {
  const getAllLiveTransactions = async (
    page: number,
    pageSize: number,
    filter?: ILiveTransactionsFilterProps,
  ): Promise<IApiResponse<IPhobeusPagedResult<IAllLiveTransactions>>> => {
    const date = filter?.date || null;
    const response = await apiProvider
      .createRequest<IPhobeusPagedResult<IAllLiveTransactions>>({ url: '/transacoes/tempo-real' })
      .withSearch({
        page,
        pageSize,
        date,
      })
      .sendAsync();

    return response;
  };

  return ({
    getAllLiveTransactions,
  });
};

export default liveTransactionService;
