/* eslint-disable max-len */
/* eslint-disable no-tabs */
import React from 'react';
import {
  Image,
} from '../../styles/_layouts/Default';
import {
  Container,
  Box,
  Title,
  Text,
  TextBold,
  Index,
  TextItem,
} from './styles';
import logo from '../../assets/logo.png';

const PrivacyPolice: React.FC = () => (
  <>
    <Container>
      <Box>
        <Title>POLÍTICA DE PRIVACIDADE E LGPD</Title>
        <Image src={logo} alt="Logotipo Tecpay" />
        <Text>
          Esta Política de Privacidade (“Política”) se aplica a
          todos os serviços prestados pela TECPAY S.A. (“TECPAY”), inscrita no CNPJ nº 23.613.543/0001-80,
          em razão da utilização pelo USUÁRIO dos serviços de:
        </Text>
        <Text>
          (a) abertura de conta de pagamento, e gestão e custódia de recursos;
        </Text>
        <Text>
          (b) recebimentos de pagamentos, em razão de transações com cartão de crédito e débito realizadas pelo USUÁRIO; e
        </Text>
        <Text>
          (c) serviços prestados na emissão de boletos.
        </Text>
        <TextBold>
          A presente Política tem o objetivo de informar, de forma clara e completa, sobre como haverá o Tratamento das
          Informações Pessoais do USUÁRIO, em decorrência da utilização dos serviços prestados pela TECPAY, conforme aplicável.
        </TextBold>
        <TextBold>
          A TECPAY divulga sua Política para proteger a privacidade do USUÁRIO, garantindo que o Tratamento das Informações
          Pessoais servirá apenas para possibilitar a prestação dos serviços.
        </TextBold>
        <TextBold>
          Ao utilizar os serviços da TECPAY, o USUÁRIO declara-se ciente com esta Política e dá expresso consentimento para o
          Tratamento de suas Informações Pessoais pela TECPAY. Caso o USUÁRIO não concorde com o Tratamento de suas Informações
          Pessoais, na forma prevista nesta Política, deverá se abster de utilizar os serviços da TECPAY.
        </TextBold>
        <TextBold>
          Caso o USUÁRIO seja pessoa jurídica, algumas condições previstas nesta Política poderão não ser aplicáveis,
          nos termos da Lei 13.709/2018.
        </TextBold>
        <TextBold>
          Para maiores informações e regras de utilização dos serviços prestados pela TECPAY, o USUÁRIO deve consultar
          o Termo aplicável.
        </TextBold>
        <Index>1. Definições</Index>
        <Text>
          1.1. Sem prejuízo de outras definições constantes nesta Política, as palavras e expressões
          abaixo indicadas, sempre que utilizadas pela primeira letra maiúscula, terão as seguintes definições:
        </Text>
        <Text>
          “Dados de Uso Técnico”: informações que a TECPAY, conforme aplicável, vierem a tratar em razão da utilização de
          dispositivo celular, computador ou outro dispositivo que o USUÁRIO utilizar para acessar o Sistema. Os Dados de Uso
          Técnico mostram como o USUÁRIO utiliza o serviço prestado pela TECPAY, incluindo o endereço IP, estatísticas sobre como
          as páginas são carregadas ou visualizadas, os sites que o USUÁRIO visitou e informações de navegação coletadas por meio
          de cookies ou tecnologia semelhante.
        </Text>
        <Text>
          “Informações do Dispositivo”: dados que podem ser coletados automaticamente de qualquer dispositivo utilizado para
          acessar o Sistema. Essas informações podem incluir, mas sem limitação, o tipo de dispositivo, conexões de rede do
          dispositivo,nome do dispositivo, endereço IP do dispositivo, informações sobre o navegador do dispositivo e a
          conexão de internet usada para acessar o Sistema, Informações de Localização Geográfica e informações sobre os
          aplicativos baixados no
          dispositivo.
        </Text>
        <Text>
          “Informações Pessoais”: informações pessoais que podem ser associadas a uma pessoa física ou pessoa jurídica
          identificada ou identificável. Podem incluir nome de pessoa física, firma ou denominação social de pessoa
          jurídica, endereço, número de telefone, e-mail, número da conta corrente ou poupança, data de nascimento,
          filiação e número de documentos oficiais (por exemplo, Carteira de Identidade – RG, passaporte, CPF, CNPJ,
          dentre outros).
        </Text>
        <Text>
          “Localização Geográfica”: informações que identificam a localização do USUÁRIO mediante, por exemplo,
          coordenadas de latitude e longitude obtidas por GPS, Wi-Fi ou triangulação de localização celular.
          O Sistema pode solicitar permissão para compartilhar a localização atual do USUÁRIO. Se o USUÁRIO não
          concordar com essa coleta das informações de Localização Geográfica, o Sistema pode não funcionar adequadamente.
        </Text>
        <Text>
          “Termo”: contrato eletrônico que regula as regras, condições e limites dos serviços a serem prestados pela DLOCAL
          e/ou DEMERGE ao USUÁRIO em razão da utilização do Sistema; incluindo, conforme aplicável, a abertura de Conta de
          Pagamento, captura de transações com cartão e remessa de recursos ao exterior.
        </Text>
        <Text>
          “Tratamento”: toda operação realizada com as Informações Pessoais do USUÁRIO, em razão da coleta, produção,
          recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento,
          arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação,
          transferência, difusão ou extração.
        </Text>
        <Text>
          “USUÁRIO”: pessoa jurídica ou pessoa física (incluindo representantes, mandatários ou prepostos autorizados
          para executar instruções de pagamento) que fornece suas Informações Pessoais para Tratamento pela TECPAY.
        </Text>
        <Index>
          2. Obtenção das Informações Pessoais
        </Index>
        <Text>
          2.1. A TECPAY realiza o Tratamento das Informações Pessoais mínimas, necessárias para a utilização,
          pelo USUÁRIO, do conjunto de serviços prestados pela TECPAY (conforme aplicável), e que permitem a abertura de
          uma “Conta de Pagamento”, seu carregamento por uma das modalidades previstas no Termo (incluindo o pagamento
          com cartão de crédito ou débito) e a realização da transferência de recursos (“Sistema”).
        </Text>
        <Text>
          2.2. Para o credenciamento ao Sistema, o USUÁRIO deverá disponibilizar as Informações Pessoais
          solicitadas pelo formulário de cadastro, incluindo, mas não se limitando: nome completo, firma ou denominação
          social; número do CPF/MF ou CNPJ/MF, número de telefone; endereço de residência ou sede, filiação, endereço de
          e-mail e outros que se façam necessários para que o USUÁRIO possa ser devidamente identificado. O formulário
          de cadastro solicita apenas dados necessários para abertura de uma Conta de Pagamento e para a prestação dos
          serviços oferecidos pela TECPAY.
        </Text>
        <Text>
          2.3. Com a finalidade de prevenir fraudes e garantir a autenticidade das informações fornecidas,
          poderão ser solicitadas outras Informações Pessoais não contidas no formulário de cadastro, bem como o envio
          de documentos que permitam a confirmação dos dados fornecidos pelo USUÁRIO. Neste caso, a TECPAY entrará em
          contato com USUÁRIO diretamente. Essas informações e documentos adicionais poderão ser armazenados pela TECPAY,
          conforme aplicável.
        </Text>
        <Text>
          2.4. A TECPAYsolicitarão os dados financeiros dos USUÁRIOS, necessários para realizar a abertura da Conta
          de Pagamento e transferência de recursos, limitados: (i) aos dados de identificação de conta bancária de titularidade
          do USUÁRIO, contendo nome e número da instituição bancária número de agência, número da conta corrente ou conta
          poupança; (ii) às informações sobre a transação; e (iii) a outras informações associadas à transação, como seu
          valor, Informações do Dispositivo, Dados de Uso Técnico e Localização Geográfica.
        </Text>
        <Text>
          2.4.1.   Os dados referentes ao cartão do USUÁRIO serão coletados apenas no momento da utilização do
          Sistema, por meio de equipamentos ou sistemas próprios para captura das transações com cartão. O Tratamento
          dos dados do cartão será realizado apenas para possibilitar a realização da transação de pagamento perante
          as credenciadoras, emissores e bandeiras. Estes dados não serão armazenados pela TECPAY.
        </Text>
        <TextItem>
          •	Os dados financeiros da TECPAY são capturados na Plataforma, de modo criptografado, dentro dos padrões de segurança
          PCI-DSS – Payment Card Industry Data Security Standard. Essa coleta se dará através do parceiro da TECPAY,
          prestador de serviços de meios de pagamento, de modo que a TECPAY não terá acesso a tais informações,
          eximindo-se de toda e qualquer responsabilidade com relação a tais informações.
        </TextItem>
        <Text>
          2.5. Antes de concluída a transferência de recursos solicitada pelo USUÁRIO, a TECPAY poderá solicitar
          outros documentos e informações que se façam necessários para a sua realização. Essas informações e documentos
          adicionais serão descartados imediatamente após a conclusão da transferência de recursos pelo USUÁRIO.
        </Text>
        <Text>
          2.5.1. A TECPAY poderá armazenar as Informações Pessoais e demais informações do USUÁRIO quando houver
          legítimo interesse da TECPAY para o cumprimento de exigência legal ou emanada das autoridades competentes.
        </Text>
        <Text>
          2.5.2.   Se a Conta de Pagamento for encerrada, a TECPAY poderá utilizar e divulgar as Informações Pessoais de
          acordo com essa Política, adotando medidas para manter anonimizáveis as Informações Pessoais, de acordo com a
          legislação vigente.
        </Text>
        <Text>
          2.6. As Informações Pessoais são obtidas mediante solicitação clara ao USUÁRIO e com a autorização expressa
          do USUÁRIO (caso aplicável), quando da criação do cadastro pelo USUÁRIO, sendo processadas com a finalidade de
          cumprimento dos serviços oferecidos de acordo com o Termo da TECPAY, conforme aplicável. Será realizado o Tratamento
          de informações adicionais do USUÁRIO apenas com base no interesse legítimo da TECPAY.
        </Text>
        <Text>
          2.7. O website e serviços de internet da TECPAY pode utilizar
          {' '}
          {' '}
          <a href="https://www.paypal.com/br/webapps/mpp/ua/privacy-full#Device%20Information" target="_blank" rel="noreferrer">
            Informações do Dispositivo
          </a>
          ,
          {' '}
          {' '}
          <a href="https://www.paypal.com/br/webapps/mpp/ua/privacy-full#Technical%20Usage%20Data" target="_blank" rel="noreferrer">
            Dados de Uso Técnico
          </a>
          {' '}
          {' '}
          e
          {' '}
          {' '}
          <a href="https://www.paypal.com/br/webapps/mpp/ua/privacy-full#Geolocation%20Information" target="_blank" rel="noreferrer">
            Localização Geográfica
          </a>
          {' '}
          {' '}
          do USUÁRIO.
        </Text>
        <Text>
          2.7.1.   Ainda, os websites e serviços de internet da TECPAY pode utilizar cookies (arquivos gravados em seu computador
          para obter informação de navegação dentro do website), para fim de confirmação de identidade e aprimoramento de sua
          navegação. Caso o USUÁRIO não concorde com sua utilização, poderá desabilitar o uso dessa função utilizando
          as opções de seu browser.
        </Text>
        <Index>
          3.	Utilização de Informações Pessoais
        </Index>
        <Text>
          3.1. As Informações Pessoais do USUÁRIO poderão ser utilizadas pela TECPAY para a formação de cadastro
          e banco de dados mediante o Tratamento das Informações Pessoais, preservando-se a individualidade e
          identificação do USUÁRIO.
        </Text>
        <Text>
          3.2. A fim de aprimorar seus serviços, a TECPAY poderá realizar o Tratamento das
          {' '}
          {' '}
          <a href="https://www.paypal.com/br/webapps/mpp/ua/privacy-full#Device%20Information" target="_blank" rel="noreferrer">
            Informações do Dispositivo
          </a>
          {' '}
          {' '}
          e
          {' '}
          {' '}
          <a href="https://www.paypal.com/br/webapps/mpp/ua/privacy-full#Technical%20Usage%20Data" target="_blank" rel="noreferrer">
            Dados de Uso Técnico
          </a>
          .
          {' '}
          {' '}
          {' '}
          Essas informações serão anonimizadas, ou seja, não possibilitarão a identificação do
          USUÁRIO em particular.
        </Text>
        <Text>
          3.3. As Informações Pessoais do USUÁRIO serão compartilhadas pela TECPAY com outros USUÁRIOS e terceiros,
          por rede segura, restritivamente ao que for necessário para identificar o USUÁRIO e os dados da transação realizada
          por meio do Sistema.
        </Text>
        <Text>
          3.4. As Informações Pessoais do USUÁRIO poderão ser compartilhadas pela TECPAY com terceiros contratados
          pera prover serviços, contanto que esses terceiros guardem o mesmo padrão de privacidade e segurança aplicados
          pela TECPAY e estejam contratualmente obrigados a não acessar o conteúdo, processar ou compartilhar as
          informações, exceto mediante ordens expressas da TECPAY.
        </Text>
        <Text>
          3.4.1. Os terceiros contratados são responsáveis pela observância e aplicação das regras e requisitos
          do PCI DSS, que se aplica a todas as entidades envolvidas nos processos de pagamento do cartão — inclusive
          comerciantes, processadores, adquirentes, emissores e prestadores de serviço. O PCI DSS também se aplica
          a todas as outras entidades que armazenam, processam ou transmitem dados do titular do cartão (CHD) e/ou
          dados de autenticação confidenciais (SAD).
        </Text>
        <Text>
          3.4.2.   O Padrão de Segurança de Dados da Indústria de Cartões de Pagamento (PCI DSS) foi
          desenvolvido para incentivar e aprimorar a segurança dos dados do titular do cartão e promover a ampla
          adoção de medidas de segurança de dados consistentes no mundo todo.
        </Text>
        <Text>
          3.4.3. O PCI DSS compreende um conjunto mínimo de requisitos para proteger os dados da conta e
          pode ser aperfeiçoado por controles e práticas adicionais para amenizar ainda mais os riscos, bem como
          as normas e leis locais, regionais e do setor. Além disso, os requisitos legais ou regulatórios podem
          exigir proteção específica para informações pessoais ou outros elementos de dados. O PCI DSS não substitui
          as leis locais ou regionais, normas governamentais ou outros requisitos legais.
        </Text>
        <Text>
          3.5. A TECPAY poderá ser obrigadas por lei ou por determinação das autoridades competentes a
          divulgar Informações Pessoais do USUÁRIO, pelo que desde já o USUÁRIO expressa seu consentimento.
        </Text>
        <Text>
          3.5.1. As Informações Pessoais também poderão ser divulgadas: (i) para empresas do grupo TECPAY;
          (ii) aos prestadores de serviços terceirizados da TECPAY; e (iii) com instituições financeiras,
          sendo que essas instituições financeiras somente poderão utilizar as Informações Pessoais para
          comercializar e oferecer produtos, de forma complementar aos serviços prestados pela TECPAY.
        </Text>
        <Text>
          3.6. Os dados e as Informações Pessoais poderão ser utilizados pela TECPAY para elaboração
          de pesquisas e estatísticas voltadas a analisar eficiência de Sistema, número de USUÁRIOS, o valor
          total de débitos pagos, entre outros, desde que tais Informações Pessoais sejam anonimizadas ou na
          forma de valores totais para a criação de estatísticas.
        </Text>
        <Text>
          3.7. A TECPAY realizará o Tratamento das Informações Pessoais do USUÁRIO, para operar
          o Sistema e prestar os serviços, conforme aplicável, nos casos a seguir:
        </Text>
        <TextItem>
          •	Para enviar ou solicitar pagamentos, informar o valor para uma Conta de Pagamento ou
          realizar a transferência entre Contas de Pagamento;
        </TextItem>
        <TextItem>
          •	Autenticar o acesso do USUÁRIO em sua Conta de Pagamento;
        </TextItem>
        <TextItem>
          •	Comunicar-se com o USUÁRIO sobre o cadastro, utilização do Sistema ou serviços;
        </TextItem>
        <TextItem>
          •	Criar a conexão da Conta de Pagamento com outra conta ou plataforma de terceiros;
        </TextItem>
        <TextItem>
          •	Realizar verificações de crédito e de reputação financeira;
        </TextItem>
        <TextItem>
          •	Manter as Informações Pessoais atualizadas e a Conta de Pagamento ativa;
        </TextItem>
        <TextItem>
          •	Monitorar e analisar o comportamento do USUÁRIO com relação à utilização do
          Sistema, dos serviços e da Conta de Pagamento;
        </TextItem>
        <TextItem>
          •	Realizar remessas ao exterior, mediante operações de câmbio contratadas com
          empresas de câmbio;
        </TextItem>
        <TextItem>
          •	Realizar a verificação da identidade do USUÁRIO para gerenciar riscos e proteger
          o Sistema, os serviços e o USUÁRIO contra fraudes. As ferramentas de riscos e prevenção
          à fraudes irão utilizar as Informações Pessoais, Informações do Dispositivo, Dados
          de Uso Técnico e Localização Geográfica;
        </TextItem>
        <TextItem>
          •	Realizar e promover campanhas de marketing e aprimoramento dos serviços ou da
          experiência de utilização do Sistema;
        </TextItem>
        <TextItem>
          •	Oferecer serviços personalizados prestados por terceiros, inclusive mediante
          a utilização de cookies;
        </TextItem>
        <TextItem>
          •	Promover ofertas de produtos ou serviços específicos do local, se o USUÁRIO
          optar por compartilhar suas informações de Localização Geográfica, mediante
          a disponibilização de anúncios, resultados de pesquisas e outros
          conteúdos personalizados;
        </TextItem>
        <TextItem>
          •	Dar cumprimento às obrigações previstas no Termo, leis e normas aplicáveis; e
        </TextItem>
        <TextItem>
          •	Sugerir conexões entre o USUÁRIO e terceiros, que o USUÁRIO talvez conheça
          ou com terceiros que possam se interessar em realizar transações por meio do
          Sistema.
        </TextItem>
        <Text>
          3.8. Ressalvado o disposto nas cláusulas acima, a TECPAY não divulga nem
          compartilha Informações Pessoais do USUÁRIO com terceiros.
        </Text>
        <Index>
          4. Comunicação
        </Index>
        <Text>
          4.1. O e-mail do USUÁRIO informado no preenchimento do cadastro,
          será utilizado como meio de comunicação pela TECPAY, apenas para o
          envio de informações a respeito do Sistema, solicitação de documentos
          e de informações relacionadas com o cadastro.
        </Text>
        <Text>
          4.2. O USUÁRIO poderá optar por não receber newsletter, materiais
          promocionais e de marketing quando do preenchimento do cadastro ou
          solicitar o cancelamento do envio de e-mails informativos mediante
          as opções disponíveis no cadastro do USUÁRIO ou posteriores alterações.
        </Text>
        <Text>
          4.3. A TECPAY não utiliza serviços de terceiros para
          enviar e-mails em seu nome. Se o USUÁRIO receber e-mail que acredita
          não ter sido enviado pela TECPAY, deverá se abster de adotar qualquer
          ação e entrar em contato imediatamente com a TECPAY, conforme
          aplicável, para confirmar sua veracidade.
        </Text>
        <Text>
          4.4. Tendo em vista a necessidade de compartilhamento de Informaçõe
          Pessoais com outros usuários, em relação aos pagamentos realizados por
          meio do Sistema, o USUÁRIO está ciente de que poderá receber telefonemas,
          e-mails e correspondências encaminhadas diretamente por tais USUÁRIOS.
        </Text>
        <Text>
          4.5.  O USUÁRIO declara-se ciente e de acordo que, para a verificar
          a realização de qualquer transação em sua Conta de Pagamento, assim
          como para consultar o carregamento, saldo e movimentação de recursos
          na Conta de Pagamento, deverá sempre acessar a Plataforma e conferir
          as informações disponibilizadas pela TECPAY não servindo como
          comprovação o mero recebimento de qualquer comunicação por outros
          meios de comunicação (incluindo e-mail, WhatsApp, telefone e SMS).
        </Text>
        <Index>
          5.	Armazenamento
        </Index>
        <Text>
          5.1. Todas as informações coletadas e processadas pela
          TECPAY são armazenadas em servidores seguros, de forma criptografada,
          com a utilização de medidas de segurança de informação constantemente
          atualizadas. As informações serão mantidas confidenciais e serão
          adotadas todas as medidas possíveis contra perda, roubo, uso indevido,
          alteração e acesso não autorizado.
        </Text>
        <Text>
          5.2. As Informações Pessoais relacionadas ao cadastro e
          movimentação da Conta de Pagamento serão armazenadas enquanto o
          USUÁRIO mantiver um cadastro ativo e utilizar os serviços da TECPAY.
        </Text>
        <Text>
          5.2.1. As Informações Pessoais poderão ser armazenadas pela TECPAY
          por até 05 (cinco) anos contados do encerramento da Conta de Pagamento,
          ou outro prazo que vier a ser determinado de acordo com a
          regulamentação vigente.
        </Text>
        <Text>
          5.3. As informações de acesso do USUÁRIO aos websites, Sistemas e serviços
          de internet da TECPAY poderão ser armazenadas pela TECPAY por até
          06 (seis) meses, de acordo com a legislação vigente.
        </Text>
        <Text>
          5.4.      A TECPAY emprega, padrões de segurança avançados, incluindo
          firewalls, antivírus e outros softwares que auxiliam na proteção de
          hackers e não vazamento das Informações Pessoais armazenadas.
          Apesar da TECPAY se dedicar a proteger o Sistema, o USUÁRIO é
          responsável por proteger e manter a privacidade de seu cadastro
          e informações de registro de Conta de Pagamento. A TECPAY não
          se responsabilizam por Informações Pessoais que o USUÁRIO
          compartilhar com terceiros.
        </Text>
        <Text>
          5.5.      Na medida da legislação aplicável, a TECPAY não se
          responsabiliza por violações ilegais de seus Sistemas que
          venham a comprometer a sua base de dados e as Informações
          Pessoais dos USUÁRIOS, bem como não se responsabiliza pela
          utilização indevida das Informações Pessoais obtidas de seu
          Sistema de forma fraudulenta ou ilícita.
        </Text>
        <Text>
          5.6.      Em caso de suspeita ou confirmação de violação de
          seu Sistema ou de perda de Informações Pessoais do USUÁRIO,
          a TECPAY envidará seus melhores esforços e tomará medidas
          imediatas para eliminar ou reduzir os riscos de danos ao
          USUÁRIO e informará os USUÁRIOS potencialmente afetados
          e às autoridades competentes de tal fato, os riscos envolvidos
          e as medidas necessárias para evitar tais danos.
        </Text>
        <Index>
          6. Modificações na Política de Privacidade
        </Index>
        <Text>
          6.1. Essa Política será revista periodicamente pela
          TECPAY para adequá-la à prestação de serviços ao USUÁRIO,
          mediante a exclusão, modificação ou inserção de novas
          cláusulas e condições.
        </Text>
        <Text>
          6.2. As alterações deverão ser informadas ao
          USUÁRIO mediante divulgação da versão atualizada e vigente
          desta Política.
        </Text>
        <Text>
          6.3. Caso o USUÁRIO não concorde com as alterações, poderá
          solicitar o encerramento de seu cadastro perante a TECPAY,
          conforme aplicável.
        </Text>
        <Text>
          6.4.  A realização do cadastro e/ou utilização do
          Sistema e dos serviços da TECPAY pelo USUÁRIO, conforme
          aplicável, serão interpretados como concordância e
          aceitação da versão vigente da Política, incluindo as
          últimas alterações realizadas, passando essas a serem
          integralmente aplicáveis.
        </Text>
        <Index>
          7.	Direitos dos Usuários relativos às Informações Pessoais
        </Index>
        <Text>
          7.1. É permitido ao USUÁRIO, a qualquer tempo, exercer os
          direitos de confirmação de Tratamento, acesso e correção ou atualizações
          de suas Informações Pessoais e demais dados, bem como de oposição ao
          Tratamento e de cancelamento de suas Informações Pessoais, mediante o
          envio de solicitação por escrito e acompanhada de prova de sua identidade
          ao endereço indicado no preâmbulo da Política.
        </Text>
        <Text>
          7.1.1.   No caso de cancelamento de suas Informações Pessoais, o USUÁRIO
          não poderá utilizar o Sistema da TECPAY, conforme aplicável.
        </Text>
        <Text>
          7.2. A TECPAY poderá contatar o USUÁRIO para confirmar sua identidade
          antes do cumprimento da solicitação, que somente será aprovada mediante a
          confirmação da identidade do USUÁRIO.
        </Text>
        <Index>
          8.	Esclarecimento de Dúvidas
        </Index>
        <Text>
          8.1. Qualquer dúvida em relação à esta Política de Privacidade poderá
          ser esclarecida por e-mail: contato@tecpay.com.br, ou no endereço da sede
          da TECPAY.
        </Text>
      </Box>
    </Container>
  </>
);

export default PrivacyPolice;
