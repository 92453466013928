import React from 'react';
import { withFormik } from 'formik';
import { Row, Col } from 'antd';
import schema from './schema';
import DatePickerField from '../../../../../components/DatePicker';
import {
  DateView,
  Button,
  Form,
  Label,
} from './styles';
import { ILiquidacaoFilterProps } from '../../../../../global/dataTransferObjects/repasses';
import { IFormProps, FormProps } from '../../types';
import { formatDate } from '../../../../../utils/formatDate';

const FormFilter: React.FC<FormProps> = (props) => {
  const { handleSubmit } = props;

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Label>Selecione uma data:</Label>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <DateView>
            <DatePickerField
              name="startDate"
              placeholder="Selecione uma data início"
              label="Data de Início"
            />
          </DateView>
        </Col>
        <Col span={6}>
          <DateView>
            <DatePickerField
              name="finishDate"
              placeholder="Selecione uma data final"
              label="Data de Término"
            />
          </DateView>
        </Col>
        <Col>
          <Button htmlType="submit">
            Consultar
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default withFormik<IFormProps, ILiquidacaoFilterProps>({
  mapPropsToValues: (): ILiquidacaoFilterProps => ({
    startDate: '',
    finishDate: '',
  }),
  validationSchema: schema,
  handleSubmit: (values, { props }) => {
    const startDate = formatDate(values.startDate);
    const finishDate = formatDate(values.finishDate);
    const form = {
      ...values,
      startDate,
      finishDate,
    };
    props.onFormSubmit(form);
  },
})(FormFilter);
