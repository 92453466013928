/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { ActionTypes } from './types';
import { ISelectedBusiness } from '../../global/dataTransferObjects/business';
import createCompanyLinkedService from '../../services/companyLikend';

export const onUpdateLoadingStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_LOADING_STATUS,
  payload: {
    status,
  },
});

export const onUpdateZeroState = (zeroState = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.ZERO_STATE_STATUS,
  payload: {
    zeroState,
  },
});

export const onGetCompanyList = (company: any) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.GET_COMPANY_LIST,
  payload: {
    company: [...company],
  },
})

// export const onGetCompanyList = (company: IPagedResult<ISelectedBusiness>) => (dispatch: Dispatch<any>): void => dispatch({
//   type: ActionTypes.GET_COMPANY_LIST,
//   payload: {
//     company: { ...company },
//   },
// });

// export const saveCompanySelected = (selectedCompany: ISelectedBusiness) => (dispatch: Dispatch<any>): void => dispatch({
//   type: ActionTypes.SELECTED_COMPANY,
//   payload: { selectedCompany: { ...selectedCompany } },
// });

// export const saveCompanySelected = (selectedCompany: ISelectedBusiness) => (dispatch: Dispatch<any>): void => dispatch({
//   type: ActionTypes.SELECTED_COMPANY,
//   payload: { selectedCompany: { ...selectedCompany } },
// });

export const saveCompanySelected = (selectedCompany: ISelectedBusiness) => async (dispatch: Dispatch<any>) => {
  try {
    dispatch({
      type: ActionTypes.SELECTED_COMPANY,
      payload: { selectedCompany: { ...selectedCompany } },
    });
  } catch (error: any) {
    console.log('error ', error.response);
  }
};

export const getCompanyLinkedList = (
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const companyLinkedService = createCompanyLinkedService();
  onUpdateLoadingStatus(true)(dispatch);
  const result = await companyLinkedService.getCompanyLinkedList();

  if (result.status !== 200) {
    onUpdateLoadingStatus(false)(dispatch);
    onUpdateZeroState(true)(dispatch);
    toast.error(result.message);
    return;
  }
  onUpdateLoadingStatus(false)(dispatch);
  onGetCompanyList(result.response)(dispatch);
};
