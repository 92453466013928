export interface ILiquidacaoDto {
  tipo: string;
  valorLiquido: number;
}

export interface IPageState {
  loading: boolean;
  zeroState: boolean;
  liquidacao: ILiquidacaoDto[];
}

export interface IAction {
  type: ActionTypes;
  payload?: {
    status?: boolean;
    liquidacao?: ILiquidacaoDto[];
    zeroState?: boolean;
  };
}

export enum ActionTypes {
  UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS',
  GET_LIQUIDACAO = 'GET_LIQUIDACAO',
  ZERO_STATE_STATUS = 'ZERO_STATE_STATUS',
}
