import React, { useMemo } from 'react';
import Modal from '../../../../../components/Modal';
import { createFormatProvider } from '../../../../../services/providers/factories';
import {
  ModalBody,
  TextGroup,
  Description,
  Item,
} from './styles';
import { IDetailsModalProps } from './types';

const DetailsModal: React.FC<IDetailsModalProps> = (props: IDetailsModalProps) => {
  const { politicaCobranca, isVisible, onClose } = props;

  const formatProvider = useMemo(() => createFormatProvider(), []);
  const formattedDate = useMemo(() => (date: Date) => (date !== undefined
    ? formatProvider.formatDate(Date.parse(date.toString()))
    : 'N/A'), []);

  const formattedValue = useMemo(() => (value: number) => (value !== undefined
    ? formatProvider.formatPrice(value).replace(/[R$%]/g, '')
    : formatProvider.formatPrice(0).replace(/[R$%]/g, '')), []);

  return (
    <Modal
      title="Detalhes da política de cobrança:"
      centered
      visible={isVisible}
      onCancel={onClose}
      width={600}
    >
      <ModalBody>
        <TextGroup>
          <Description>Identificador:</Description>
          <Item>{politicaCobranca.id}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Nome:</Description>
          <Item>{politicaCobranca.name}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Descrição:</Description>
          <Item>{politicaCobranca.description}</Item>
        </TextGroup>
        <TextGroup>
          <Description>De:</Description>
          <Item>{formattedDate(politicaCobranca.from)}</Item>
        </TextGroup>
        <TextGroup>
          <Description>To:</Description>
          <Item>{formattedDate(politicaCobranca.to)}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Tarifa de adesão:</Description>
          <Item>{`R$ ${formattedValue(politicaCobranca.anticipationFee)}`}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Taxa de débito Visa (Stone):</Description>
          <Item>{`R$ ${formattedValue(politicaCobranca.debitFee)}`}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Taxa de débito MasterCard (Stone):</Description>
          <Item>{`R$ ${formattedValue(politicaCobranca.debitFeeMasterCard)}`}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Taxa de débito Amex (Stone):</Description>
          <Item>{`R$ ${formattedValue(politicaCobranca.debitFeeAmex)}`}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Taxa de débito Hipercard (Stone):</Description>
          <Item>{`R$ ${formattedValue(politicaCobranca.debitFeeHipercard)}`}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Taxa de débito Elo (Stone):</Description>
          <Item>{`R$ ${formattedValue(politicaCobranca.debitFeeElo)}`}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Taxa de débito Visa (Adiq):</Description>
          <Item>{`R$ ${formattedValue(politicaCobranca.debitFeeVisaAdiq)}`}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Taxa de débito MasterCard (Adiq):</Description>
          <Item>{`R$ ${formattedValue(politicaCobranca.debitFeeMasterCardAdiq)}`}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Taxa de débito Amex (Adiq):</Description>
          <Item>{`R$ ${formattedValue(politicaCobranca.debitFeeAmexAdiq)}`}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Taxa de débito Hipercard (Adiq):</Description>
          <Item>{`R$ ${formattedValue(politicaCobranca.debitFeeHipercardAdiq)}`}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Taxa de débito Elo (Adiq):</Description>
          <Item>{`R$ ${formattedValue(politicaCobranca.debitFeeEloAdiq)}`}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Taxa de antecipação padrão:</Description>
          <Item>{`R$ ${formattedValue(politicaCobranca.anticipationFee)}`}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Taxa Extra - Primeira Parcela (MATHON):</Description>
          <Item>{`R$ ${formattedValue(politicaCobranca.taxaExtra)}`}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Valor mínimo para poder solicitar antecipação:</Description>
          <Item>{`R$ ${formattedValue(politicaCobranca.minAnticipationAmount)}`}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Custo de Originação:</Description>
          <Item>{`% ${formattedValue(politicaCobranca.anticipationComissionFee)}`}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Limite de antecipação:</Description>
          <Item>{`% ${formattedValue(politicaCobranca.anticipationLimit)}`}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Float de débito (D+1, D+2, etc):</Description>
          <Item>{politicaCobranca.debitFloat}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Dias de carência do aluguel:</Description>
          <Item>{politicaCobranca.daysGracePeriod}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Valor mínimo da transação para cobrar valor fixo:</Description>
          <Item>{`R$ ${formattedValue(politicaCobranca.minimumTransactionValueToChargeFixedFee)}`}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Valor fixo a ser cobrando quando transação mínima não for alcançada:</Description>
          <Item>{`R$ ${formattedValue(politicaCobranca.fixedFeeToChargeOnMinimumTransaction)}`}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Tarifa de reversão contra estorno:</Description>
          <Item>{`R$ ${formattedValue(politicaCobranca.dailyProvisionValue)}`}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Valor mínimo para repasse:</Description>
          <Item>{`R$ ${formattedValue(politicaCobranca.valorMinimoRepasse)}`}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Originação arquivo CIP:</Description>
          <Item>{`% ${formattedValue(politicaCobranca.originacaoArquivoCip)}`}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Mensalidade da conta:</Description>
          <Item>{`R$ ${formattedValue(politicaCobranca.mensalidadeConta)}`}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Tarifa para geração de boleto em lote:</Description>
          <Item>{`R$ ${formattedValue(politicaCobranca.tarifaGeracaoBoletoLote)}`}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Taxa para recebimento do PIX:</Description>
          <Item>{`R$ ${formattedValue(politicaCobranca.taxaPixRecebimento)}`}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Taxa para pagamento do PIX:</Description>
          <Item>{`R$ ${formattedValue(politicaCobranca.taxaPixPagamento)}`}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Mensalidade do cartão:</Description>
          <Item>{`R$ ${formattedValue(politicaCobranca.mensalidadeCartao)}`}</Item>
        </TextGroup>
        <TextGroup>
          <Description>Empresas utilizando essa política:</Description>
          <Item>{politicaCobranca.businessAmount}</Item>
        </TextGroup>
      </ModalBody>
    </Modal>
  );
};

export default DetailsModal;
