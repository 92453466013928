import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import ResetPasswordForm from './resetPasswordForm';
import { IFormValues } from './resetPasswordForm/types';
import { RoutesEnum } from '../../routes/types';
import { IResetPasswordProps } from './types';

const SignUp: React.FC<IResetPasswordProps> = (props: IResetPasswordProps) => {
  const history = useHistory();
  const location = useLocation();
  const { isLoading, isAuthenticated } = props;
  const [validToken, setValidToken] = useState<string | string[] | null>(null);
  const [isTokenValid, setIsTokenValid] = useState<boolean>(true);

  useEffect(() => {
    const onUpdateResetToken = async () => {
      const { token } = queryString.parse(location.search);
      history.replace(location.pathname);
      const result = await props.validateResetToken({ token });
      if (!result) {
        setIsTokenValid(false);
        return;
      }

      setValidToken(token);
    }

    onUpdateResetToken();
  }, []);

  const onResetPasswordFormSubmit = async (values: IFormValues) => {
    const result = await props.resetPassword({ ...values, token: validToken });
    if (!result) {
      return;
    }

    history.push(RoutesEnum.SignIn);
  };

  if (isAuthenticated) {
    history.push(RoutesEnum.Veiculos);
    return null;
  }

  return (
    <ResetPasswordForm
      loading={isLoading}
      onResetPasswordFormSubmit={onResetPasswordFormSubmit}
      isTokenValid={isTokenValid}
    />
  );
}

export default SignUp;
