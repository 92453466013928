import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';
import { DateUtils } from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';
import locale from 'antd/lib/date-picker/locale/pt_BR';
import Label from '../Forms/FieldLabel';
import { DatePickerFieldProps } from './types';
import {
  DatePickerStyled,
  Form,
  ErrorMessage,
} from './styles';

function parseDate(str: string, format: string): Date | undefined {
  const parsed = dateFnsParse(str, format, new Date());
  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return undefined;
}

function formatDate(date: Date, format: string): string {
  if (!date) return '';
  const newDate = date.setHours(0, 0, 0, 0);
  return dateFnsFormat(newDate, format);
}

const DatePickerField: React.FC<DatePickerFieldProps> = (props: DatePickerFieldProps) => {
  const {
    name,
    placeholder,
    label,
    required,
  } = props;

  const [field, meta, helpers] = useField<Date | null>(name);
  const [internalValue, setInternalValue] = useState<string>(field.value ? formatDate(field.value, 'dd/MM/yyyy') : '');

  const isInvalid = meta.error && meta.touched;

  useEffect(() => {
    if (field.value) {
      setInternalValue(formatDate(field.value, 'dd/MM/yyyy'));
    } else {
      setInternalValue('');
    }
  }, [field.value]);

  const handleDayChange = (day: Date | undefined) => {
    if (day && DateUtils.isDate(day)) {
      helpers.setValue(day);
    } else {
      helpers.setValue(null);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInternalValue(e.target.value);
    const parsedDate = parseDate(e.target.value, 'dd/MM/yyyy');
    if (parsedDate) {
      helpers.setValue(parsedDate);
    } else {
      helpers.setValue(null);
    }
  };

  return (
    <Form>
      {!!label && (
        <Label
          htmlFor={name}
          text={label}
          required={required}
        />
      )}
      <DatePickerStyled
        classNames={{
          container: `${isInvalid ? 'is-invalid' : ''} DayPickerInput`,
          overlayWrapper: 'DayPickerInput-OverlayWrapper',
          overlay: 'DayPickerInput-Overlay',
        }}
        inputProps={{
          style: { flex: 1, borderWidth: 1, borderColor: '#d9d9d9' },
          locale,
          value: internalValue,
          onChange: handleInputChange,
        }}
        format="dd/MM/yyyy"
        placeholder={placeholder}
        parseDate={parseDate}
        formatDate={formatDate}
        onDayChange={handleDayChange}
        onBlur={field.onBlur}
        dayPickerProps={{ localeUtils: MomentLocaleUtils, locale: 'pt-Br' }}
      />
      {meta.error && (
        <ErrorMessage>
          {meta.error}
        </ErrorMessage>
      )}
    </Form>
  );
};

export default DatePickerField;
