/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const formatDate = (date: any) => {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) { month = `0${month}`; }
  if (day.length < 2) { day = `0${day}`; }

  // output format: yyyy-mm-dd
  return [year, month, day].join('-');
}
