import React, {
  useReducer,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  EyeOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import Table, { TableColumn } from '../../components/Table';
import LoadingOverlay from '../../components/LoadingOverlay';
import { Reducer, InitialState } from './actions/reducer';
import { BusinessDto, IFilterBusinessProps } from '../../global/dataTransferObjects/credencial';
import FilterForm from './components/FilterForm';
import ViewBusinessModal from './components/viewBusinessModal';
import {
  getAllBusiness,
  openBusinessViewModal,
  onUpdateBusinessModalStatus,
} from './actions';
import {
  Button,
  Container,
  Link,
  TableContainer,
} from './styles';
import { T2 } from '../../styles/titles';

const Business: React.FC = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const [pageSize, setPageSize] = useState<number>(20);
  const [offSet] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filterOrder, setFilterOrder] = useState<any>();

  const businessItem: any = state.business.records.map((item) => ({
    ...item,
    documentNumber: item.cnpj || item.cpf,
  }));

  const formatUserType = (user: number) => {
    if (user === 1) {
      return 'Pessoa Física';
    }
    if (user === 2) {
      return 'Pessoa Jurídica';
    }
    return 'Administrador';
  };

  const fetchAllBusiness = useCallback(() => getAllBusiness(offSet, pageSize)(dispatch), []);
  useEffect(() => {
    fetchAllBusiness();
  }, [fetchAllBusiness]);

  const fetchAllBusinessPagination = (initialPage: number, finalPage: number) => {
    getAllBusiness(initialPage, finalPage, filterOrder)(dispatch);
  };

  const handleViewModalStatus = (status: boolean) => onUpdateBusinessModalStatus(status)(dispatch);

  const handleOpenViewUBusinessModal = (business: BusinessDto) => {
    openBusinessViewModal({ ...business })(dispatch)
  }

  const handleBusinessFilter = async (filter: IFilterBusinessProps) => {
    setFilterOrder(filter);
    getAllBusiness(offSet, pageSize, filter)(dispatch);
  };

  const formatDocument = (documentNumber: string) => {
    const documentNumberFormatted = documentNumber.replace(/[^0-9]/g, '');
    if (documentNumberFormatted.length === 11) {
      const cpf = documentNumberFormatted.match(/^([0-9]{3})([0-9]{3})([0-9]{3})([0-9]{2})/);
      if (cpf != null) {
        const cpfFormatted = `${cpf[1]}.${cpf[2]}.${cpf[3]}-${cpf[4]}`;
        return cpfFormatted;
      }
    }
    if (documentNumberFormatted.length === 14) {
      const cnpj = documentNumberFormatted.match(/^([0-9]{2})([0-9]{3})([0-9]{3})([0-9]{4})([0-9]{2})/);
      if (cnpj != null) {
        const cnpjFormatted = `${cnpj[1]}.${cnpj[2]}.${cnpj[3]}/${cnpj[4]}-${cnpj[5]}`;
        return cnpjFormatted;
      }
    }
    return documentNumberFormatted;
  }

  useEffect(() => {
    if (currentPage > 1) {
      fetchAllBusinessPagination(pageSize * (currentPage - 1), (currentPage * pageSize))
    }
    if (currentPage === 1) {
      fetchAllBusinessPagination(0, (currentPage * pageSize));
    }
  }, [currentPage, pageSize]);

  return (
    <Container>
      <LoadingOverlay
        show={state.isPaymentModalOpen ? false : state.loading}
        relative
      />
      <T2>Empresas</T2>
      <FilterForm
        onFormSubmit={handleBusinessFilter}
      />
      <Link to="/tecpay/admin/empresas/novaEmpresa">
        <Button icon={<PlusOutlined />}>Cadastrar nova empresa</Button>
      </Link>
      <TableContainer>
        <Table
          dataSource={businessItem}
          pagination={
            {
              showSizeChanger: true,
              onShowSizeChange: (current: number, size: number) => {
                setPageSize(size);
              },
              defaultPageSize: 20,
              total: state.business.count,
              onChange: (pageNum) => {
                setCurrentPage(pageNum);
              },
            }
          }
        >
          <TableColumn title="Business ID" dataIndex="id" key="id" />
          <TableColumn title="Tipo" dataIndex="type" key="type" render={formatUserType} />
          <TableColumn title="CNPJ/CPF" dataIndex="documentNumber" key="documentNumber" render={formatDocument} />
          <TableColumn title="Nome" dataIndex="name" key="name" />
          <TableColumn title="Contato" dataIndex="contact" key="contact" />
          <TableColumn title="Telefone" dataIndex="telephone1" key="telephone1" />
          <TableColumn title="Cidade" dataIndex="addressCity" key="addressCity" />
          <TableColumn title="UF" dataIndex="addressState" key="addressState" />
          <TableColumn
            title="Visualizar"
            dataIndex="visualizar"
            key="visualizar"
            render={(i: any, e: any) => (state.business.records.length >= 1 ? (
              <EyeOutlined
                onClick={() => handleOpenViewUBusinessModal(e)}
              />
            ) : null)}
          />
        </Table>
      </TableContainer>
      <ViewBusinessModal
        business={{ ...state.businessInfo }}
        isVisible={state.isBusinessModalOpen}
        onClose={() => handleViewModalStatus(false)}
      />
    </Container>
  );
};

export default Business;
