import { AdiqArquivoPagamentoRetorno, Detalhe } from '../../../../global/dataTransferObjects/arquivoRetorno';

import { IPagedResult } from '../../../../global/generics';

export interface IPageState {
  loading: boolean;
  arquivoRetorno: IPagedResult<AdiqArquivoPagamentoRetorno>;
  zeroState: boolean;
  isViewArquivoRetornorModalOpen: boolean;
  detalhe: Detalhe[];
}

export enum ActionTypes {
  UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS',
  GET_ARQUIVO_RETORNO = 'GET_ARQUIVO_RETORNO',
  ZERO_STATE_STATUS = 'ZERO_STATE_STATUS',
  VIEW_ARQUIVO_RETORNO_MODAL_STATUS = 'VIEW_ARQUIVO_RETORNO_MODAL_STATUS',
  SELECT_ARQUIVO_RETORNO_DETALHES = 'SELECT_ARQUIVO_RETORNO_DETALHES'
}

export interface IAction {
  type: ActionTypes;
  payload?: any;
}
