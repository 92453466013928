import React from 'react';
import { withFormik } from 'formik';
import logo from '../../../assets/logo.png';
import validationSchema from './validationSchema';
import { IFormValues, IFormProps, FormProps } from './types';
import LoadingOverlay from '../../../components/LoadingOverlay';
import FormField from '../../../components/Forms/Input';
import { Row, Col } from './styles';
import { Form } from '../../../styles/forms';
import { Button } from '../../../styles/buttons';
import {
  Container,
  Box,
  Image,
} from '../../../styles/_layouts/Default'

const InnerForm = (props: FormProps) => {
  const { handleSubmit, loading } = props;

  return (
    <Container>
      <Row>
        <Col>
          <Box>
            <LoadingOverlay relative show={loading} />
            <Image src={logo} alt="Logotipo Tecpay" />
            <Form onSubmit={handleSubmit} noValidate>
              <FormField
                name="password"
                type="password"
                label="Sua senha de suporte"
                placeholder="Digite sua senha de suporte"
                required
              />
              <Button htmlType="submit">
                Entrar
              </Button>
            </Form>
          </Box>
        </Col>
      </Row>
    </Container>
  )
}

const SignForm = withFormik<IFormProps, IFormValues>({
  mapPropsToValues: () => ({
    password: '',
  }),
  validationSchema,
  handleSubmit: async (values, { props }): Promise<void> => {
    await props.onSignInFormSubmit(values);
  },
})(InnerForm);

export default SignForm;
