import { IArranjoDto } from '../../../global/dataTransferObjects/arranjos';
import { IPagedResult } from '../../../global/generics';

export interface IPageState {
  loading: boolean,
  arranjos: IPagedResult<IArranjoDto>;
}

export enum ActionTypes {
  UPDATE_ARRANJOS = 'UPDATE_ARRANJOS',
}
