import { IPagedResult } from '../../global/generics';
import { IArquivoRetornoService } from '../@types/services';
import { IApiResponse, ITecpayApi } from '../providers/types';
import {
  IArquivoRetornoFilterProps,
  IDetalhesRepassesFilterProps,
} from '../../global/dataTransferObjects/repasses';
import { AdiqArquivoPagamentoRetorno } from '../../global/dataTransferObjects/arquivoRetorno';

const arquivoRetornoService = (apiProvider: ITecpayApi): IArquivoRetornoService => {
  const getArquivoRetorno = async (
    offset: number,
    pageSize: number,
    filter?: IDetalhesRepassesFilterProps,
  ): Promise<IApiResponse<IPagedResult<AdiqArquivoPagamentoRetorno>>> => {
    const dataReferencia = filter?.startDate || new Date().toLocaleDateString('en-CA').replace(/-/g, '/');
    const finishDate = filter?.finishDate || null;
    const response = await apiProvider
      .createRequest<IPagedResult<AdiqArquivoPagamentoRetorno>>({ url: 'adiq/arquivo-retorno' })
      .withSearch({
        offset,
        pageSize,
        dataReferencia,
        finishDate,
      })
      .sendAsync();

    return response;
  };

  const getArquivoRetornoBs2 = async (
    offset: number,
    pageSize: number,
    filter?: IArquivoRetornoFilterProps,
  ): Promise<IApiResponse<IPagedResult<AdiqArquivoPagamentoRetorno>>> => {
    const dataReferencia = filter?.dataReferencia || new Date().toLocaleDateString('en-CA').replace(/-/g, '/');
    const response = await apiProvider
      .createRequest<IPagedResult<AdiqArquivoPagamentoRetorno>>({ url: 'bs2/arquivo-retorno' })
      .withSearch({
        offset,
        pageSize,
        dataReferencia,
      })
      .sendAsync();

    return response;
  };

  return ({
    getArquivoRetorno,
    getArquivoRetornoBs2,
  });
};

export default arquivoRetornoService;
