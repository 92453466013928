import React, { useEffect } from 'react';
import { withFormik } from 'formik';
import Modal from '../../../components/Modal';
import LoadingOverlay from '../../../components/LoadingOverlay';
import { ModalBody } from '../styles';
import { formatterDocumentNumber } from '../../../components/Masks';
import { IEditUserModalProps, IEditUserProps } from '../types';
import validationSchema from './validationSchema';
import FormInput from '../../../components/Forms/Input';
import { InputGroup } from '../../../styles/inputs';
import {
  Form,
  Space,
  Button,
  Warning,
  Checkbox,
} from './styles';

const EditUserModal: React.FC<IEditUserModalProps> = (props: any) => {
  const {
    user, isVisible, onClose, setFieldValue, handleSubmit, loading, values: {
      documentNumber,
      phoneNumber,
    },
  } = props;

  const handleResetPassword = (e: boolean) => {
    setFieldValue('resetPassword', e)
  }

  useEffect(() => {
    setFieldValue('id', user.id);
    setFieldValue('name', user.name);
    setFieldValue('lastName', user.lastName);
    setFieldValue('userType', user.userType);
    setFieldValue('email', user.email);
    setFieldValue('documentNumber', formatterDocumentNumber(user.documentNumber));
    setFieldValue('phoneNumber', user.phoneNumber);
    setFieldValue('companyName', user.companyName);
    setFieldValue('tradingName', user.tradingName);
  }, [user]);

  useEffect(() => {
    const documentNumberConst = documentNumber?.replace(/[^0-9]/g, '');
    setFieldValue('documentNumber', documentNumber);
    const handleDocumentNumber = () => {
      if (documentNumberConst.length === 11) {
        const cpf = documentNumberConst.match(/^([0-9]{3})([0-9]{3})([0-9]{3})([0-9]{2})/);
        const cpfFormatted = `${cpf[1]}.${cpf[2]}.${cpf[3]}-${cpf[4]}`;
        setFieldValue('documentNumber', cpfFormatted);
      }
      if (documentNumberConst.length === 14) {
        const cnpj = documentNumberConst.match(/^([0-9]{2})([0-9]{3})([0-9]{3})([0-9]{4})([0-9]{2})/);
        const cnpjFormatted = `${cnpj[1]}.${cnpj[2]}.${cnpj[3]}/${cnpj[4]}-${cnpj[5]}`;
        setFieldValue('documentNumber', cnpjFormatted);
      }
    }
    handleDocumentNumber();
  }, [documentNumber]);

  useEffect(() => {
    const phoneNumberConst = phoneNumber?.replace(/[^0-9]/g, '');
    setFieldValue('phoneNumber', phoneNumberConst);
    const handlePhoneNumber = () => {
      if (phoneNumberConst.length === 11) {
        const telNumber: RegExpMatchArray | null = phoneNumberConst.match(/^([0-9]{2})([0-9]{5})([0-9]{4})/);
        if (telNumber !== null) {
          setFieldValue('phoneNumber', `(${telNumber[1]}) ${telNumber[2]}-${telNumber[3]}`);
        }
      }
      if (phoneNumberConst.length === 10) {
        const telNumber: RegExpMatchArray | null = phoneNumberConst.match(/^([0-9]{2})([0-9]{4})([0-9]{4})/);
        if (telNumber !== null) {
          setFieldValue('phoneNumber', `(${telNumber[1]}) ${telNumber[2]}-${telNumber[3]}`);
        }
      }
      return phoneNumberConst;
    }
    handlePhoneNumber();
  }, [phoneNumber]);

  return (
    <Modal
      title="Editar Informações do Usuário"
      centered
      visible={isVisible}
      onCancel={onClose}
      width={600}
    >
      <Form onSubmit={handleSubmit}>
        <LoadingOverlay
          show={loading}
          relative
          tip="Realizando o cadastro da solicitação, por favor aguarde..."
        />
        <ModalBody>
          <InputGroup>
            <FormInput
              type="text"
              name="name"
              label="Nome"
              required
            />
            <FormInput
              type="text"
              name="lastName"
              label="Sobrenome"
              required
            />
          </InputGroup>
          <InputGroup>
            <FormInput
              type="text"
              name="email"
              label="Email"
              required
            />
            <FormInput
              type="text"
              name="documentNumber"
              label="Documento"
              required
            />
          </InputGroup>
          {user.userType === 2
          && (
            <InputGroup>
              <FormInput
                type="text"
                name="companyName"
                label="Razão Social"
                required
              />
              <FormInput
                type="text"
                name="tradingName"
                label="Nome Fantasia"
                required
              />
            </InputGroup>
          )}
          <FormInput
            type="text"
            name="phoneNumber"
            label="Telefone"
            required
          />
          <InputGroup>
            <Checkbox onChange={(e) => handleResetPassword(e.target.checked)} name="resetPassword">Redefinir senha</Checkbox>
          </InputGroup>
        </ModalBody>
        <Space />
        <Button htmlType="submit">
          Salvar
        </Button>
        <Warning>
          **A solicitação pode levar algum tempo até ser analisada.
        </Warning>
      </Form>
    </Modal>
  );
};

export default withFormik<IEditUserModalProps, IEditUserProps>({
  mapPropsToValues: (): IEditUserProps => ({
    id: '',
    name: '',
    lastName: '',
    email: '',
    documentNumber: '',
    phoneNumber: '',
    companyName: '',
    tradingName: '',
    userType: 1,
    resetPassword: false,
  }),
  validationSchema,
  handleSubmit: (values, { props }) => {
    const documentNumber = values.documentNumber.replace(/[^0-9]/g, '');
    const phoneNumber = values.phoneNumber.replace(/[^0-9]/g, '');
    const formattedValues = {
      ...values,
      documentNumber,
      phoneNumber,
    };
    props.onFormSubmit(formattedValues);
  },
})(EditUserModal);
