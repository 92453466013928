import React, {
  useCallback,
  useEffect,
  useReducer,
  useState,
  useMemo,
} from 'react';
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusOutlined,
  ProfileOutlined,
} from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { Popconfirm } from 'antd';
import { Reducer, InitialState } from './actions/reducer';
import Table, { TableColumn } from '../../../components/Table';
import { Button, Container, TableContainer } from './styles';
import LoadingOverlay from '../../../components/LoadingOverlay';
import { T2 } from '../../../styles/titles';
import {
  updatePoliticasCobrancas,
  deletePoliticaCobranca,
  openDetailsModal,
  onUpdatePoliticaCobrancaModalStatus,
} from './actions';
import Filter from './components/filterForm';
import { IPoliticaCobrancaDTO, IPoliticaCobrancaFilterProps } from '../../../global/dataTransferObjects/politicaCobranca';
import { IFormValues } from './components/filterForm/types';
import { createFormatProvider } from '../../../services/providers/factories';
import DetailsModal from './components/detailsModal';

const ListPoliticaCobranca: React.FC = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const history = useHistory();
  const [offset] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(20);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [politicaCobrancaFilter, setPoliticaCobrancaFilter] = useState<IPoliticaCobrancaFilterProps>({});
  const formatProvider = useMemo(() => createFormatProvider(), []);

  const handleButtonClick = () => history.push('/tecpay/admin/cadastro-geral/politicas-cobranca/cadastrar');

  const fetchPoliticasCobrancas = useCallback((
    initial: number = offset,
    size: number = pageSize,
    filter: IPoliticaCobrancaFilterProps = politicaCobrancaFilter,
  ) => updatePoliticasCobrancas(initial, size, filter)(dispatch), []);

  useEffect(() => {
    if (currentPage > 1) {
      fetchPoliticasCobrancas(pageSize * (currentPage - 1), (currentPage * pageSize))
      return;
    }
    if (currentPage === 1) {
      fetchPoliticasCobrancas(0, (currentPage * pageSize));
    }
  }, [currentPage, pageSize]);

  const handleDeletePoliticaCobranca = async (politicaCobrancaId: number): Promise<void> => {
    const result = await deletePoliticaCobranca(politicaCobrancaId)(dispatch);
    if (!result) {
      return;
    }

    fetchPoliticasCobrancas();
  };

  const handleFilterSubmit = async (values: IFormValues): Promise<void> => {
    const formattedValues: IPoliticaCobrancaFilterProps = {
      ...values,
    };

    setPoliticaCobrancaFilter({ ...formattedValues });
    fetchPoliticasCobrancas(offset, pageSize, { ...formattedValues });
  };

  const formattedDate = useMemo(() => (date: Date) => (date !== undefined
    ? formatProvider.formatDate(Date.parse(date.toString()))
    : 'N/A'), []);

  const handleOpenDetailsModal = (politicaCobrancaDTO: IPoliticaCobrancaDTO) => {
    openDetailsModal({ ...politicaCobrancaDTO })(dispatch);
  }

  const handleDetailsModalStatus = (status: boolean) => onUpdatePoliticaCobrancaModalStatus(status)(dispatch);

  return (
    <Container>
      <LoadingOverlay show={state.loading} relative />
      <T2>Políticas de cobrança</T2>
      <Button
        icon={<PlusOutlined />}
        onClick={handleButtonClick}
      >
        Nova politica de cobrança
      </Button>
      <Filter
        onSubmit={handleFilterSubmit}
      />
      <TableContainer>
        <Table
          dataSource={[...state.politicasCobrancas.records]}
          pagination={
            {
              showSizeChanger: true,
              onShowSizeChange: (current: number, size: number) => {
                setPageSize(size);
              },
              defaultPageSize: 20,
              total: state.politicasCobrancas.count,
              onChange: (page: number) => {
                setCurrentPage(page);
              },
            }
          }
        >
          <TableColumn
            title="Excluir"
            dataIndex="excluir"
            key="excluir"
            align="center"
            render={(value: any, record: any) => (state.politicasCobrancas.records.length >= 1 ? (
              <Popconfirm
                title="Deseja realmente excluir?"
                cancelText="Cancelar"
                okText="Excluir"
                onConfirm={() => handleDeletePoliticaCobranca(record.id)}
              >
                <DeleteOutlined />
              </Popconfirm>
            ) : null)}
          />
          <TableColumn
            title="Editar"
            dataIndex="editar"
            key="editar"
            align="center"
            render={(value: any, record: any) => (state.politicasCobrancas.records.length >= 1 ? (
              <Link
                style={{ color: '#000' }}
                to={`/tecpay/admin/cadastro-geral/politicas-cobranca/editar/${record.id}`}
              >
                <EditOutlined />
              </Link>
            ) : null)}
          />
          <TableColumn
            title="Nome"
            dataIndex="name"
            key="name"
          />
          <TableColumn title="Descrição" dataIndex="description" key="description" />
          <TableColumn title="De" dataIndex="from" key="from" render={formattedDate} />
          <TableColumn title="Para" dataIndex="to" key="to" render={formattedDate} />
          <TableColumn
            title="Empresas vinculadas"
            dataIndex="businessAmount"
            key="businessAmount"
            align="center"
            render={(value: any, record: any) => (state.politicasCobrancas.records.length >= 1 ? (
              <Link
                style={{ color: '#000', textDecoration: 'underline' }}
                to={`/tecpay/admin/cadastro-geral/politicas-cobranca/business?id=${record.id}`}
              >
                <span style={{ paddingRight: 4 }}>{record.businessAmount}</span>
                <ProfileOutlined />
              </Link>
            ) : null)}
          />
          <TableColumn
            title="Visualizar"
            dataIndex="visualizar"
            key="visualizar"
            align="center"
            render={(value: any, record: any) => (state.politicasCobrancas.records.length >= 1 ? (
              <EyeOutlined
                onClick={() => handleOpenDetailsModal(record)}
              />
            ) : null)}
          />
        </Table>
      </TableContainer>
      <DetailsModal
        politicaCobranca={{ ...state.politicaCobranca }}
        isVisible={state.isDetailsModalOpen}
        onClose={() => handleDetailsModalStatus(false)}
      />
    </Container>
  )
};

export default ListPoliticaCobranca;
