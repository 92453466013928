import styled from 'styled-components';
import { Layout, Card as AntdCard } from 'antd';

const { Meta: AntdMeta } = AntdCard;

export const Container = styled(Layout)`
  padding: 0.5rem 1rem;
  gap: 1rem;
  position: relative;
  align-items: flex-end;

  @media (min-width: 768px) {
    padding: 1rem 2rem;
  }
`;

export const Card = styled(AntdCard).attrs({
  bodyStyle: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '1rem',
  },
})`
  width: 20rem;
`;

export const Meta = styled(AntdMeta)`
  font-size: 2rem;
`;
