import React from 'react';
import { withFormik } from 'formik';
import { KeyOutlined } from '@ant-design/icons';
import { schema } from './schema';
import {
  IFormValues,
  IFormProps,
  FormProps,
} from './types';
import LoadingOverlay from '../../../components/LoadingOverlay';
import FormField from '../../../components/Forms/Input';
import {
  Row,
  Col,
  VerticalLine,
} from './styles';
import { Form } from '../../../styles/forms';
import { Button } from '../../../styles/buttons';
import { Link, LinkGroup, Anchor } from '../../../styles/links';
import {
  Container,
  Box,
  Span,
} from '../../../styles/_layouts/Default';

const InnerForm = (props: FormProps) => {
  const {
    handleSubmit,
    loading,
    isTokenValid,
  } = props;

  return (
    <Container>
      <Row>
        <Col>
          <Box>
            <KeyOutlined style={{ fontSize: 200, color: '#169ead' }} />
            <LoadingOverlay relative show={loading} />
            {isTokenValid ? (
              <Form onSubmit={handleSubmit} noValidate>
                <FormField
                  name="password"
                  type="password"
                  label="Senha"
                  placeholder="Digite sua senha"
                  required
                />
                <FormField
                  name="passwordConfirmation"
                  type="password"
                  label="Confirmação de senha"
                  placeholder="Digite sua senha novamente"
                  required
                />
                <Button htmlType="submit">
                  Redefinir senha
                </Button>
              </Form>
            )
              : (
                <>
                  <div style={{ marginTop: '20px', fontSize: '18px', textAlign: 'center' }}>
                    A validação do token falhou. Se o token expirou, você pode obter um novo na página
                    {' '}
                    <Link to="/esqueci-minha-senha">esqueci minha senha.</Link>
                  </div>
                  <VerticalLine />
                </>

              )}
            <LinkGroup>
              <Span>Qualquer dúvida ou problema com redefinição de senha, entrar em contato com nosso suporte:</Span>
              <Anchor href="mailto:contato@tecpay.com.br">contato@tecpay.com.br</Anchor>
            </LinkGroup>
          </Box>
        </Col>
      </Row>
    </Container>
  )
}

const ResetPasswordForm = withFormik<IFormProps, IFormValues>({
  mapPropsToValues: () => ({
    password: '',
    passwordConfirmation: '',
  }),
  validationSchema: schema,
  handleSubmit: async (values, { props }): Promise<void> => props.onResetPasswordFormSubmit({ ...values }),
})(InnerForm);

export default ResetPasswordForm;
