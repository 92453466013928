import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { BarDatum } from '../../../../../global/dataTransferObjects/repasses';

interface BarChartProps {
  data: BarDatum[];
}

const BarChart: React.FC<BarChartProps> = ({ data }) => (
  <ResponsiveBar
    data={data}
    keys={['valorLiquido']}
    indexBy="tipo"
    margin={{
      top: 80, right: 130, bottom: 50, left: 80,
    }}
    padding={0.3}
    valueScale={{ type: 'linear' }}
    indexScale={{ type: 'band', round: true }}
    colors={(bar) => {
      switch (bar.data.tipo) {
        case 'Crédito':
          return '#69b3a2';
        case 'Crédito Pré Pago':
          return '#ffcc00';
        case 'Débito':
          return '#ff6666';
        default:
          return '#cccccc';
      }
    }}
    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Tipo',
      legendPosition: 'start',
      legendOffset: 32,
    }}
    axisLeft={{
      tickSize: 1,
      tickPadding: 1,
      tickRotation: 0,
      legend: 'Valor Líquido',
      legendPosition: 'middle',
      legendOffset: -75,
      format: (value) => `R$ ${value.toLocaleString('pt-BR')}`,
    }}
    label={(d) => `R$ ${d?.value?.toLocaleString('pt-BR')}`}
    labelSkipHeight={12}
    labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
    tooltip={({ id, value, color }) => (
      <div
        style={{
          padding: 12,
          color,
          background: '#222',
        }}
      >
        <strong>{id === 'valorLiquido' ? 'Valor Líquido' : id}</strong>
        <br />
        {`R$ ${value.toLocaleString('pt-BR')}`}
      </div>
    )}
    legends={[
      {
        dataFrom: 'indexes',
        anchor: 'bottom-right',
        direction: 'column',
        justify: false,
        translateX: 120,
        translateY: 0,
        itemsSpacing: 2,
        itemWidth: 110,
        itemHeight: 20,
        itemDirection: 'left-to-right',
        itemOpacity: 0.85,
        symbolSize: 20,
        effects: [
          {
            on: 'hover',
            style: {
              itemOpacity: 1,
            },
          },
        ],
        data: [
          { id: 'Crédito', label: 'Crédito', color: '#69b3a2' },
          { id: 'Crédito Pré Pago', label: 'Crédito Pré Pago', color: '#ffcc00' },
          { id: 'Débito', label: 'Débito', color: '#ff6666' },
        ],
      },
    ]}
    animate
    motionConfig="gentle"
    theme={{
      axis: {
        ticks: {
          text: {
            fontSize: 13,
            fontWeight: 'bold',
          },
        },
        legend: {
          text: {
            fontSize: 14,
            fontWeight: 'bold',
          },
        },
      },
      labels: {
        text: {
          fontSize: 12,
          fontWeight: 'bold',
        },
      },
    }}
  />
);

export default BarChart;
