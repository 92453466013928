import React, { useReducer, useCallback, useEffect } from 'react';
import { Reducer, InitialState } from './actions/reducer';
import { Container } from './styles';
import { T2 } from '../../../styles/titles';
import { getallLiquidacao } from './actions';
import { ILiquidacaoFilterProps, BarDatum, ILiquidacaoDto } from '../../../global/dataTransferObjects/repasses';
import FormFilter from './components/filter';
import LoadingOverlay from '../../../components/LoadingOverlay';
import BarChart from './components/Chart/barChart';

const PainelLiquidacao: React.FC = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);

  const fetchAllLiquidacao = useCallback(() => getallLiquidacao()(dispatch), []);

  const allLiquidacoes: BarDatum[] = (state.liquidacao || []).map((item: ILiquidacaoDto) => ({
    tipo: item.tipo,
    valorLiquido: item.valorLiquido,
  }));

  const handleFilter = async (filter: ILiquidacaoFilterProps) => {
    getallLiquidacao(filter)(dispatch);
  };

  useEffect(() => {
    fetchAllLiquidacao();
  }, [fetchAllLiquidacao]);

  return (
    <Container>
      <LoadingOverlay show={state.loading} relative />
      <T2>Painel de liquidação EDI</T2>
      <FormFilter onFormSubmit={handleFilter} />
      {allLiquidacoes.length > 0 && (
        <div style={{ height: '500px', marginTop: '20px' }}>
          <BarChart data={allLiquidacoes} />
        </div>
      )}
    </Container>
  );
};

export default PainelLiquidacao;
