import React, { useMemo } from 'react';
import Modal from '../../../../components/Modal';
import {
  ModalBody,
  TableContainer,
} from '../styles';
import { IViewDetalhesArquivoRetornoModalProps } from '../types';
import { createFormatProvider } from '../../../../services/providers/factories';

import LoadingOverlay from '../../../../components/LoadingOverlay';
import Table, { TableColumn } from '../../../../components/Table';

const DetalhesArquivoRetornoModal: React.FC<IViewDetalhesArquivoRetornoModalProps> = (
  props: IViewDetalhesArquivoRetornoModalProps,
) => {
  const {
    detalhes,
    isVisible,
    onClose,
    loading,
  } = props;

  const formatProvider = useMemo(() => createFormatProvider(), []);
  const formattedDate = (date: string) => formatProvider.formatDate(Date.parse(date));

  const { format } = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  return (
    <Modal
      title="Detalhes Arquivo de retorno"
      centered
      visible={isVisible}
      onCancel={onClose}
      width={1000}
    >
      <LoadingOverlay
        show={loading}
        relative
      />
      <ModalBody>
        <TableContainer>
          <>
            <Table
              dataSource={detalhes}
            >
              <TableColumn
                title="N° Seq. Linha Arquivo"
                dataIndex="numeroSequencialLinhaDetalhe"
                key="numeroSequencialLinhaDetalhe"
                render={(item: string) => ({
                  children: <div>{item}</div>,
                })}
              />
              <TableColumn
                title="Data Operação"
                dataIndex="dataOperacao"
                key="dataOperacao"
                render={(item: string) => ({
                  children: <div>{formattedDate(item)}</div>,
                })}
              />
              <TableColumn
                title="Banco Crédito"
                dataIndex="codigoBancoCredito"
                key="codigoBancoCredito"
                render={(item: string) => ({
                  children: <div>{item}</div>,
                })}
              />
              <TableColumn
                title="Agência Crédito"
                dataIndex="agenciaBancariaCredito"
                key="agenciaBancariaCredito"
                render={(item: string) => ({
                  children: <div>{item}</div>,
                })}
              />
              <TableColumn
                title="Conta Crédito"
                dataIndex="numeroContaCredito"
                key="numeroContaCredito"
                render={(item: string) => ({
                  children: <div>{item}</div>,
                })}
              />
              <TableColumn
                title="N° Beneficiário"
                dataIndex="numeroIdentificacaoBeneficiario"
                key="numeroIdentificacaoBeneficiario"
                render={(item: string) => ({
                  children: <div>{item}</div>,
                })}
              />
              <TableColumn
                title="Beneficiário"
                dataIndex="nomeBeneficiario"
                key="nomeBeneficiario"
                render={(item: string) => ({
                  children: <div>{item}</div>,
                })}
              />
              <TableColumn
                title="Valor Pagamento"
                dataIndex="valorPagamento"
                key="valorPagamento"
                render={(item: number) => ({
                  children: <div>{format(item)}</div>,
                })}
              />
              <TableColumn
                title="Código Retorno"
                dataIndex="codigoRetorno"
                key="codigoRetorno"
                render={(item: number) => ({
                  children: <div>{item}</div>,
                })}
              />
            </Table>
          </>
        </TableContainer>
      </ModalBody>
    </Modal>
  );
};

export default DetalhesArquivoRetornoModal;
