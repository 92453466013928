import React, {
  useReducer,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { EyeOutlined } from '@ant-design/icons';
import Table, { TableColumn } from '../../../components/Table';
import FormFilter from './components';
import { Reducer, InitialState } from './actions/reducer';
import {
  Container,
  TableContainer,
} from './styles';
import { T2 } from '../../../styles/titles';
import { createFormatProvider } from '../../../services/providers/factories';
import {
  getArquivoRetornoBs2,
  onViewArquivoRetornoModalStatus,
  onSelectArquivoRetornoDetalhes,
} from './actions';
import { IArquivoRetornoFilterProps } from '../../../global/dataTransferObjects/repasses';
import LoadingOverlay from '../../../components/LoadingOverlay';
import ViewDetalhesRetornoModal from './components/viewDetalhesRetornoModal';
import { Detalhe } from '../../../global/dataTransferObjects/arquivoRetorno';

const ArquivoRetornoBs2: React.FC = () => {
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const [pageSize, setPageSize] = useState<number>(20);
  const [offSet] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filterOrder, setFilterOrder] = useState<any>();

  const { format } = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const formatProvider = useMemo(() => createFormatProvider(), []);
  const formattedDate = (date: string) => formatProvider.formatDate(Date.parse(date));

  const repassesPagination = (initialPage: number, finalPage :number) => {
    getArquivoRetornoBs2(initialPage, finalPage, filterOrder)(dispatch);
  }

  useEffect(() => {
    if (currentPage > 1) {
      repassesPagination(pageSize * (currentPage - 1), (pageSize))
    }
    if (currentPage === 1) {
      repassesPagination(0, (pageSize));
    }
  }, [currentPage, pageSize]);

  const handleArquivoRetornoFilter = async (filter: IArquivoRetornoFilterProps) => {
    setFilterOrder(filter);
    getArquivoRetornoBs2(offSet, pageSize, filter)(dispatch);
  }

  const allArquivoRetorno = state?.arquivoRetorno?.records.map((record, index) => ({
    ...record.cabecalho,
    detalhes: record.detalhe,
    key: index,
  }));

  const handleViewModalSelected = (detalhes: Detalhe[]) => {
    onSelectArquivoRetornoDetalhes(detalhes)(dispatch);
  }

  const handleViewModalStatus = (status: boolean) => onViewArquivoRetornoModalStatus(status)(dispatch);

  return (
    <Container>
      <LoadingOverlay
        show={state.loading}
        relative
      />
      <T2>Arquivo de Retorno PIX - BS2</T2>
      <FormFilter onFormSubmit={handleArquivoRetornoFilter} />
      <TableContainer>
        <Table
          dataSource={allArquivoRetorno}
          pagination={
            {
              showSizeChanger: true,
              onShowSizeChange: (current: number, size: number) => {
                setPageSize(size);
              },
              defaultPageSize: 20,
              total: state.arquivoRetorno.count,
              onChange: (pageNum) => {
                setCurrentPage(pageNum);
              },
            }
          }
        >
          <TableColumn
            title="Visualizar"
            dataIndex="visualizar"
            key="visualizar"
            render={(_, record: any) => (
              <EyeOutlined onClick={() => handleViewModalSelected(record.detalhes)} />
            )}
          />
          <TableColumn
            title="Data do Arquivo"
            dataIndex="dataCriacaoArquivo"
            key="dataCriacaoArquivo"
            render={(item: string) => ({
              children: <div>{formattedDate(item)}</div>,
            })}
          />
          <TableColumn
            title="N° Seq. Diário Arquivo"
            dataIndex="numeroSequencialDiario"
            key="numeroSequencialDiario"
            render={(item: string) => ({
              children: <div>{item}</div>,
            })}
          />
          <TableColumn
            title="Remetente"
            dataIndex="nomeRemetente"
            key="nomeRemetente"
            render={(item: string) => ({
              children: <div>{item}</div>,
            })}
          />
          <TableColumn
            title="Valor Total dos Pagamentos"
            dataIndex="valorTotalPagamentos"
            key="valorTotalPagamentos"
            render={(item: number) => ({
              children: <div>{format(item)}</div>,
            })}
          />
          <TableColumn
            title="Numero Total de Pagamentos"
            dataIndex="numeroTotalPagamentos"
            key="numeroTotalPagamentos"
            render={(item: number) => ({
              children: <div>{item}</div>,
            })}
          />
          <TableColumn
            title="Código Retorno"
            dataIndex="codigoRetorno"
            key="codigoRetorno"
            render={(item: string) => ({
              children: <div>{item}</div>,
            })}
          />
        </Table>
      </TableContainer>
      <ViewDetalhesRetornoModal
        detalhes={[...state.detalhe]}
        isVisible={state.isViewArquivoRetornorModalOpen}
        onClose={() => handleViewModalStatus(false)}
        loading={state.loading}
      />
    </Container>
  );
};

export default ArquivoRetornoBs2;
